import { useRef, useState } from 'react';
// material
import { alpha, useTheme } from '@mui/material/styles';
import { MenuItem, Typography, ListItemText, IconButton } from '@mui/material';
// hooks
import useLocales from '../../hooks/useLocales';
// components
import MenuPopover from '../../components/MenuPopover';
import { MIconButton } from '../../components/@material-extend';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const theme = useTheme();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { allLang, currentLang, onChangeLang } = useLocales();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeLang = (value) => {
    onChangeLang(value);
    handleClose();
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.focusOpacity)
          })
        }}
      >
        <Typography variant="subtitle2" sx={{ color: theme.palette.text.primary }}>
          {currentLang.label}
        </Typography>
      </IconButton>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ py: 1 }}>
        {allLang.map((option) => (
          <MenuItem
            key={option.value}
            selected={option.value === currentLang.value}
            onClick={() => handleChangeLang(option.value)}
            sx={{ py: 1, px: 1.5 }}
          >
            <ListItemText primaryTypographyProps={{ variant: 'subtitle2', color: theme.palette.text.primary }}>
              {option.label}
            </ListItemText>
          </MenuItem>
        ))}
      </MenuPopover>
    </>
  );
}
