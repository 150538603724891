/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router components
import { Link, useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui core components
import { Box, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Toolbar from "@mui/material/Toolbar";
import { useDispatch, useSelector } from "react-redux";
import team2 from "../../../assets/images/team-2.jpg";
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";
import useAuth from "../../../hooks/useAuth";
import useOffSetTop from '../../../hooks/useOffSetTop';
import AccountPopover from "../../../layouts/dashboard/AccountPopover";
import CompanyPopover from "../../../layouts/dashboard/CompanyPopover";
import {
  setMiniSidenav,
  setOpenConfigurator, setTransparentNavbar, useSoftUIController
} from "../../../softcontext";
import Breadcrumbs from "../../Breadcrumbs";
import NotificationItem from "../../Items/NotificationItem";
import {
  navbar,
  navbarContainer, navbarDesktopMenu, navbarRow
} from "./styles";

function DashboardNavbar({ absolute, light, isMini, name }) {
  const { user } = useAuth();
  const dispatchredux = useDispatch();
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const { company } = useSelector((state) => state.company);
  const { journey, contract } = useSelector((state) => state.site);
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const isHome = pathname === '/';

  // Add this mapping for steps
  const pathToStep = {
    'prospect': 0,
    'understand': 1,
    'strategize': 2,
    'program': 3,
    'solution': 4
  };

  // Get the current step based on the path
  const currentStep = pathToStep[route[route.length - 1]] || 0;

  useEffect(() => {
    // Setting the navbar type
    /*   if (fixedNavbar) {
        setNavbarType("sticky");
      } else {
        setNavbarType("static");
      } */

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem
        image={<img src={team2} alt="person" />}
        title={["New message", "from Laur"]}
        date="13 minutes ago"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            payment
          </Icon>
        }
        title={["October f-up", "Available"]}
        date="1 day"
        onClick={handleCloseMenu}
      />
      <NotificationItem
        color="secondary"
        image={
          <Icon fontSize="small" sx={{ color: ({ palette: { white } }) => white.main }}>
            payment
          </Icon>
        }
        title={["Read -> Simulation", "Subscription change"]}
        date="2 days"
        onClick={handleCloseMenu}
      />
    </Menu>
  );
/*   if (!company) return <LoadingScreen />;
 */  return (
    <AppBar
      position={"sticky"}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light })}
    >
      <Toolbar sx={(theme) => ({
        ...navbarContainer(theme),
        position: 'relative'
      })}>
        <SoftBox 
          color="inherit" 
          mb={{ xs: 1, md: 0 }} 
          sx={(theme) => ({
            ...navbarRow(theme, { isMini }),
            position: 'relative',
            left: miniSidenav ? '-9rem' : 0,
            transition: 'left 200ms ease-in-out',
            display: 'flex',
            alignItems: 'center'
          })}
        >
          <Icon fontSize="medium" sx={navbarDesktopMenu} onClick={handleMiniSidenav}>
            {miniSidenav ? "menu_open" : "menu"}
          </Icon>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} name={name} />
        </SoftBox>
        {isMini ? null : (
          <SoftBox sx={(theme) => navbarRow(theme, { isMini })}>
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
              <Stepper 
                activeStep={currentStep} 
                alternativeLabel 
                sx={{ 
                  flexGrow: 3,
                  my: 0,
                  mt: -1.5,
                  height: 'fit-content',
                  alignItems: 'center',
                  minHeight: 'unset',
                  mx: 'auto',
                  width: '40%',
                  position: 'absolute',
                  left: '55%',
                  transform: 'translateX(-50%)',
                  zIndex: 0,
                  maxWidth: 'calc(100% - 500px)'
                }}
              >
                {["Prospect", "Understand", "Strategize", "Program", "Solution"].map((label) => (
                  <Step key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
            <SoftBox pr={1}>
              {/*  <SoftInput
                placeholder="Search..."
                icon={{ component: "search", direction: "left" }}
              /> */}
              {contract?.id && (
                <>
                  <Stack direction="row" spacing={1} sx={{ height: '100%', alignItems: 'center' }}>
                    <Icon sx={{ height: '100%' }}>

                      description
                    </Icon>
                    <Box>
                      <Box sx={{ mb: -2 }}>
                        <SoftTypography
                          variant="button"
                          fontWeight="bold"
                          sx={{ mr: 0.5 }}>
                          {contract?.name}
                        </SoftTypography>
                      </Box>
                      <Box sx={{ alignItems: 'center' }}>

                        <Link to="/">
                          <SoftTypography
                            variant="caption"
                            to="/"
                            fontWeight="light">
                            Switch Site
                          </SoftTypography>
                        </Link>
                      </Box>
                    </Box>
                    <IconButton to="/profile/overview">
                      <Icon>
                        chevron_right
                      </Icon>
                    </IconButton>
                  </Stack>
                </>
              )}
            </SoftBox>
            <Stack direction="row" spacing={{ xs: 0.5, sm: 1.5, height: '100%' }}>

              <Stack sx={{ display: 'flex', height: '100%' }}>
                <SoftTypography
                  variant="button"
                  fontWeight="medium"
                >
                  {user?.displayName}
                </SoftTypography>
                <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                  {user?.companyName}
                </Typography>
              </Stack>{/* 
              <SettingModeMenu isOffset={isOffset} isHome={isHome} /> */}
              <AccountPopover company={company} />

              {renderMenu()}

            </Stack>
          </SoftBox>
        )}
      </Toolbar>
    </AppBar >
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  name: '',
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  name: PropTypes.string,
};

export default DashboardNavbar;
