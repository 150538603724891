/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Soft UI Dashboard PRO React components
import SoftBox from "../SoftBox";

// Custom styles for the SoftDropzone
import SoftDropzoneRoot from "./SoftDropzoneRoot";

function SoftDropzone({ options, onFile, url }) {
  const dropzoneRef = useRef();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    console.log(files);
    if (files.length) {
      onFile(files[0]);
    }
  }, [files]);
  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      
      const dropzone = new Dropzone(dropzoneRef.current, {
        url, // Replace with your actual upload endpoint
        maxFilesize: 5, // Max file size in MB
        acceptedFiles: "image/*,application/pdf", // Accepted file types
        ...options,
        init: function () {
          this.on("addedfile", file => {
            setFiles(prevFiles => [...prevFiles, { file, progress: 0, status: 'added' }]);
          });
          this.on("uploadprogress", (file, progress) => {
            setFiles(prevFiles => 
              prevFiles.map(f => 
                f.file === file ? { ...f, progress } : f
              )
            );
          });
          this.on("success", (file, response) => {
            setFiles(prevFiles => 
              prevFiles.map(f => 
                f.file === file ? { ...f, status: 'success', response } : f
              )
            );
          });
          this.on("error", (file, errorMessage) => {
            setFiles(prevFiles => 
              prevFiles.map(f => 
                f.file === file ? { ...f, status: 'error', errorMessage } : f
              )
            );
          });
          this.on("removedfile", file => {
            setFiles(prevFiles => prevFiles.filter(f => f.file !== file));
          });
        }
      });
      return dropzone;
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0) Dropzone.instances.forEach((dz) => dz.destroy());
    }

    const dropzone = createDropzone();

    return () => {
      removeDropzone();
      dropzone.destroy();
    };
  }, [options]);

  return (
    <SoftDropzoneRoot
      component="form"
      //action="/file-upload"
      ref={dropzoneRef}
      className="form-control dropzone"
    >
      <SoftBox className="fallback">
        <SoftBox component="input" name="file" type="file" multiple />
      </SoftBox>
    </SoftDropzoneRoot>
  );
}

// Typechecking props for the SoftDropzone
SoftDropzone.propTypes = {
  options: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default SoftDropzone;
