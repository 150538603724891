// ----------------------------------------------------------------------
export function workplacerMagicManager(submissions, workplaces) {
  console.log("manager magic started", submissions);
  const { employees, sqm, rent, growth, remotePolicy, spaceFormula, percentageFormula, officeDays, fullyErgonomicDesks, fullyRemoteAgreement, occupancy, openWorkArea, otherArea, qtFullyRemote, secondWord, smallRooms, socialArea, firstWord, individualRooms, largeRooms, lectureRooms, thirdWord } = submissions;
  const remoteprecence = remotePolicy === "Fully remote" ? 0 : remotePolicy === "Fully office" ? 1 : officeDays / 5;
  const percentageFactor = spaceFormula === "Always for everyone" ? 1 : spaceFormula === "Percentage of total workforce" ? percentageFormula / 100 : remoteprecence;
  const netEmployees = employees * (officeDays / 5) * (100 + (growth ||0)) / 100;
  const activityBase = submissions.activityAnswers;
  const activityCategories = [
    "Individual",
    "Collaboration small",
    "Collaboration large",
    "Other",
  ];
  // FIND HYBRID ANSWERS MY OWN OFFICE
  const activityCalculation = activityBase.map((a) => {
    // DO WORKPLACE MAPPING
    const workplaceData = submissions.workplaceAnswers.find((w) =>
      w.name.includes(a.name)
    );
    // TODO: N/A MAPPING
    //console.log('workplaceData', workplaceData, a, submissions.workplaceAnswers);
    const officeShare = workplaceData?.average || 0;
    const activityShare = a.average;
   // console.log('activity share', activityShare, officeShare);
    const activityWorkplaceShare = (activityShare * officeShare) / 100;

    // DO SOUND MAPPING
    if (officeShare === 0) return {...a, activityShare, officeShare: 0, activityShareAtOffice: 0};

    const obj = {
      ...a,
      activityShare,
      officeShare,
      activityShareAtOffice: activityWorkplaceShare,
    };
    // console.log('obj', obj);
    return obj;
  });
  // console.log('activityCalculation', activityCalculation);
  // console.log('activityCalculation', activityCalculation.filter((a) => a !== null));
  // OFFICE ACTIVITY PROFILE AND ADJUST FOR 100%  (OFFICE ACTIVITY PROFILE)
  const officeActivityProfile = activityCalculation.filter((a) => a !== null).map((a) => {

    const soundData = submissions.soundAnswers.find((w) =>
      w.name.includes(a.name)
    );
    // console.log('soundData', submissions.soundAnswers);

    const roomCount =
      soundData?.options.find((o) => o.option === "Room")?.count || 0;
    const screenedCount =
      soundData?.options.find((o) => o.option === "Screened")?.count || 0;
    const openCount =
      soundData?.options.find((o) => o.option === "Open")?.count || 0;

    const roomShare =
      (roomCount / (roomCount + screenedCount + openCount)) * 100;
    const screenedShare =
      (screenedCount / (roomCount + screenedCount + openCount)) * 100;
    const openShare =
      (openCount / (roomCount + screenedCount + openCount)) * 100;
      // console.log('openShare', openShare);
    const preferredSoundEnvironment =
      roomShare > screenedShare && roomShare > openShare
        ? "Room"
        : screenedShare > roomShare && screenedShare > openShare
          ? "Screened"
          : "Open";
    // NORMALIZE ACTIVITY SHARES TO MATCH 100% OF WORKPLACE SHARES
    const officeActivityProfile =
      (100 * a.activityShareAtOffice) /
      activityCalculation.filter((a) => a !== null).reduce(
        (acc, curr) => acc + curr.activityShareAtOffice,
        0
      );

    const shareOfTotalRoom = (officeActivityProfile * roomShare) / 100;
    const shareOfTotalScreeened =
      (officeActivityProfile * screenedShare) / 100;
    const shareOfTotalOpen = (officeActivityProfile * openShare) / 100;
    const obj = {
      ...a,
      officeActivityProfile,
      preferredSoundEnvironment,
      room: {
        share: roomShare,
        shareOfTotal: shareOfTotalRoom,
      },
      open: {
        share: openShare,
        shareOfTotal: shareOfTotalOpen,
      },
      screened: {
        share: screenedShare,
        shareOfTotal: shareOfTotalScreeened,
      },
    };
    //console.log('ROOM SAR', obj);
    return obj;
  });
  // console.log('officeActivityProfile', officeActivityProfile);
  //USE SHARES OF TOTAL AND CALCULATE ACTIVITY PROFILES FOR EACH ROOM TYPE ("OFFICE LAYOUT")
  const activityRoomNormalized = officeActivityProfile.map((a) => {

    // ROOM
    const sumShareOfTotalRoom = officeActivityProfile.reduce(
      (acc, curr) => acc + curr.room.shareOfTotal,
      0
    ) || 0;
    const roomActivityProfile =
      (a.room.shareOfTotal / sumShareOfTotalRoom) * 100 || 0;
    // OPEN
    const sumShareOfTotalOpen = officeActivityProfile.reduce(
      (acc, curr) => acc + curr.open.shareOfTotal,
      0
    ) || 0;

    const openActivityProfile =
      (a.open.shareOfTotal / sumShareOfTotalOpen) * 100 || 0;
    // SCREENED
    const sumShareOfTotalScreened = officeActivityProfile.reduce(
      (acc, curr) => acc + curr.screened.shareOfTotal,
      0
    ) || 0;
    const screenedActivityProfile =
      (a.screened.shareOfTotal / sumShareOfTotalScreened) * 100 || 0;

    //CREATE OBJECT AND RETURN
    const obj = {
      ...a,
      room: {
        ...a.room,
        activityProfile: roomActivityProfile,
      },
      open: {
        ...a.open,
        activityProfile: openActivityProfile,
      },
      screened: {
        ...a.screened,
        activityProfile: screenedActivityProfile,
      }
    };
    return obj;
  });

  const workplaceSums = workplaces.map((workplace) => {
    const roomtype = workplace.soundenv;
     //console.log('workplace,', workplace);
    const activitySums = workplace.workplaceprofiles.map((activityProfile) => {
      const activity = activityRoomNormalized.find((a) => a.activity_id === activityProfile.activityprofile_id);
      if (!activity) return null;
      // console.log('activity', activity, activityProfile);
      if (roomtype === "Screened") {
        const activitySum = activity?.screened.activityProfile * activityProfile.share;
        return activitySum;
      } else if (roomtype === "Open") {
        const activitySum = activity?.open.activityProfile * activityProfile.share;
        return activitySum;
      } else {
        const activitySum = activity?.room.activityProfile * activityProfile.share;
        return activitySum;
      }
    })
    // console.log('activitysums', activitySums);
    const shareOfTotal = activitySums.reduce((acc, curr) => acc + curr, 0);
    const fte = netEmployees * percentageFactor * shareOfTotal / 100;
    const units = fte / workplace.ppl;
    const unitsRoundedUp = Math.ceil(units);
    const sqm = unitsRoundedUp * workplace.size;
    const isErgonomic = workplace.is_ergonomic
    const isSmallRoom = workplace.ppl < 5 && workplace.soundenv === "Room" && workplace.ppl > 1;
    const isLargeRoom = workplace.ppl < 12 && workplace.soundenv === "Room" && workplace.ppl > 4;
    const obj = {
      ...workplace,
      fte,
      units,
      unitsRoundedUp,
      sqm,
      isSmallRoom,
      isLargeRoom,
      isErgonomic,
    };
    return obj;
  })
  // console.log('CHECK HERE', workplaceSums);
  const workplaceSumsTotal = workplaceSums.reduce((acc, curr) => acc + curr.sqm, 0);
  // ROOMS -----------------------------------------------------

  const roomAggregates = ["Room", "Screened", "Open"].map((room) => {
    let output = {};
    if (room === "Room") {
      const normalizedShare = activityRoomNormalized.reduce(
        (acc, curr) => acc + curr.room.shareOfTotal,
        0
      );
      const inefficientSpace = workplaceSums.filter((wp) => wp.soundenv === "Room").reduce((acc, curr) => acc + curr.sqm, 0);
      const innefficientShare = (100 * inefficientSpace) / workplaceSumsTotal;
      output = { room, normalizedShare, innefficientShare };
    } else if (room === "Screened") {
      const normalizedShare = activityRoomNormalized.reduce(
        (acc, curr) => acc + curr.screened.shareOfTotal,
        0
      );
      const inefficientSpace = workplaceSums.filter((wp) => wp.soundenv === "Screened").reduce((acc, curr) => acc + curr.sqm, 0) || 0;
      const innefficientShare = (100 * inefficientSpace) / workplaceSumsTotal;
      output = { room, normalizedShare, innefficientShare };
    } else if (room === "Open") {
      const normalizedShare = activityRoomNormalized.reduce(
        (acc, curr) => acc + curr.open.shareOfTotal,
        0
      );
      const inefficientSpace = workplaceSums.filter((wp) => wp.soundenv === "Open").reduce((acc, curr) => acc + curr.sqm, 0);
      const innefficientShare = (100 * inefficientSpace) / workplaceSumsTotal;
      output = { room, normalizedShare, innefficientShare };
    }
    return output;
  });

  // console.log("roomaggregates", roomAggregates);
  /* 
  const activityProfileCategories = activityCategories.map((cat) => {
    const answers = activityBase.filter((a) => a.category === cat);
    const categorySum = answers.reduce((acc, curr) => acc + curr.average, 0);
    const categoryAvg = categorySum / answers.length;
    return {
      category: cat,
      answers,
      categorySum,
      categoryAvg,
    };
  }); */

  const categoryProfiles = activityCategories.map((cat) => {
    const questions = officeActivityProfile.filter((a) => a.category === cat);
    const categorySum = questions.reduce(
      (acc, curr) => acc + curr.normalizedShare,
      0
    );
    const categoryAvg = categorySum / questions.length;
    return {
      category: cat,
      categorySum,
      categoryAvg,
    };
  });

   // console.log("normalized categories", categoryProfiles);
  return { officeActivityProfile, categoryProfiles, need: workplaceSumsTotal, workplaceSums, spaceToday: sqm, employees, percentageFormula, spaceFormula, remotePolicy, officeDays, growth, fullyErgonomicDesks, fullyRemoteAgreement, occupancy, openWorkArea, otherArea, qtFullyRemote, secondWord, smallRooms, socialArea, firstWord, individualRooms, largeRooms, lectureRooms, thirdWord, sqmrent: Math.round(rent / sqm) || 0 };
}

