import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';
// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box style={{ alignItems: 'center' }} sx={{ alignItems: 'center', width: 60, height: '100%', ...sx }}>
      <img src="/static/lohow.png" alt="Logo" />
    </Box>
  );
}
