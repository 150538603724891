import FastForwardRounded from '@mui/icons-material/FastForwardRounded';
import FastRewindRounded from '@mui/icons-material/FastRewindRounded';
import { Autocomplete, Box, Card, CardHeader, Chip, Container, Divider, Grid, Slider, Stack, TextField, Typography, useMediaQuery } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import useLocales from '../../../hooks/useLocales';
// ----------------------------------------------------------------------

const PrettoSlider = styled(Slider)(({ theme }) => ({
  color: '#3a8589',
  height: 3,
  padding: '13px 0',
  '& .MuiSlider-thumb': {
    height: 12,
    width: 12,
    backgroundColor: '#fff',
    border: '1px solid currentColor',
    '&:hover': {
      boxShadow: '0 0 0 8px rgba(58, 133, 137, 0.16)',
    },
    '& .airbnb-bar': {
      height: 9,
      width: 1,
      backgroundColor: 'currentColor',
      marginLeft: 1,
      marginRight: 1,
    },
  },
  '& .MuiSlider-track': {
    height: 3,
  },
  '& .MuiSlider-rail': {
    color: theme.palette.mode === 'dark' ? '#bfbfbf' : '#d8d8d8',
    opacity: theme.palette.mode === 'dark' ? undefined : 1,
    height: 3,
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#000',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
}));
const RootStyle = styled(motion.div)(({ theme }) => ({
  position: 'relative',
  backgroundImage:
    theme.palette.mode === 'light'
      ? `linear-gradient(0deg, ${alpha(theme.palette.grey[300], 0)} -10%, ${theme.palette.common.white} 40%)`
      : `linear-gradient(0deg, ${alpha(theme.palette.grey[300], 0)} -10%, ${theme.palette.background.default} 40%)`,
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    alignItems: 'top'
  }
}));

const ChevronStyle = styled(motion.div)(() => ({
  bottom: 20,
  width: '100%',
  display: 'flex',
  position: 'fixed',
  justifyContent: 'center'
}));

const ContentStyle = styled((props) => <Stack spacing={0} {...props} />)(({ theme }) => ({
  zIndex: 10,
  margin: 'auto',
  width: '100%',
  p: 3,
  textAlign: 'center',
  position: 'relative',
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5)
}));
const ContentStyleFull = styled((props) => <Stack spacing={0} {...props} />)(({ theme }) => ({
  zIndex: 10,
  margin: 'auto',
  width: '100%',
  height: '50%',
  textAlign: 'center',
  alignItems: 'center',
  position: 'relative',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(0)
}));

// ----------------------------------------------------------------------

export default function SpaceCalculator({ workplaces, wfhd }) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const [sqm, setSqm] = useState(2000);
  const [employees, setEmployees] = useState(workplaces || 100);
  const [wfh, setWfh] = useState(wfhd || 0);
  const [lunchPrice, setLunchPrice] = useState(150);
  const [homeLunchPrice, setHomeLunchPrice] = useState(40);
  const [homeCoffeeCost, setHomeCoffeeCost] = useState(10);
  const [carCost, setCarCost] = useState(300);
  const [publicFee, setPublicFee] = useState(970);
  const [shareCar, setShareCar] = useState(20);
  const [sharePublic, setSharePublic] = useState(60);
  const [shareWalk, setShareWalk] = useState(10);
  const [shareBike, setShareBike] = useState(10);
  const [share20minWalk, setShare20minWalk] = useState(0);

  const [workDays, setWorkDays] = useState(20);
  const [analysis, setAnalysis] = useState('');
console.log('wfhd,', wfhd);
  useEffect(() => {
    setAnalysis(calculateSpace());
  }, [wfh, employees, homeCoffeeCost, homeLunchPrice, shareCar, sharePublic, lunchPrice, wfhd]);
  function calculateSpace() {
    console.log('wfh', wfh);
    //  const array = densitySwitch(density);
    const savingsMovable = employees * ((lunchPrice - homeLunchPrice - homeCoffeeCost + carCost * shareCar / 100) * workDays);
    const costsFull = employees * ((lunchPrice + carCost * shareCar / 100) * workDays + sharePublic / 100 * publicFee);
    const savingsFixed = publicFee * sharePublic / 100 * employees;

    const savingsStatic = wfh / 100 * savingsMovable;
    const savingsConditionalPublic = wfh > 50 ? savingsFixed : wfh > 30 ? 0.5 * savingsFixed : 0;


    const savingsStatic3 = 30 / 100 * savingsMovable;
    const savingsConditionalPublic3 = 0.5 * savingsFixed;

    const savingsStatic5 = 50 / 100 * savingsMovable;
    const savingsConditionalPublic5 = savingsFixed;
    const cost100 = employees * (homeLunchPrice + homeCoffeeCost);

    const obj = { cost: costsFull, savings: savingsStatic + savingsConditionalPublic, thirty: savingsStatic3 + savingsConditionalPublic3, fifty: savingsStatic5 + savingsConditionalPublic5, hundred: savingsMovable + savingsFixed, zero: 0 };
    return obj;
  }
  const lightIconColor =
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';
  const { translate } = useLocales();

  const colorScaleUp = [theme.palette.error.dark, theme.palette.warning.dark, theme.palette.warning.main, theme.palette.success.light, theme.palette.success.main, theme.palette.success.dark, theme.palette.success.dark, theme.palette.success.darker, theme.palette.success.darker, theme.palette.primary.dark, theme.palette.primary.darker];
  function ltvColor(ltv) {
    if (ltv > 0) {
      return theme.palette.success.main
    } else if (ltv < 0) {
      return theme.palette.error.main
    } else {
      return lightIconColor
    }
  }
  // <Grid spacing={2} sx={{ mb: 1, px: 1, height: 300, width: '100%', display: 'flex', justifyContent: 'center', alignItems="center" }}>

  return (
    <Container sx={{ width: '100%' }}>
      <ContentStyle>
        <Grid container spacing={5}>

          <Grid item xs={12}>
            <CardHeader title={'Vad kostar det era anställda att vara på kontoret?'} subheader={`Vi beräknar vilka ytterligare kostnader som tillkommer för den anställde och vad den kollektiva effekten blir.`} />

          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <Stack>
                <Typography gutterBottom variant="caption" sx={{ color: 'text.primary' }}>
                  {translate('Kostnad lunch').toUpperCase()}
                </Typography>
              </Stack>
              <PrettoSlider
                aria-label="Lunch price"
                value={lunchPrice}
                min={0}
                step={5}
                marks
                valueLabelDisplay="auto"
                max={100}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setLunchPrice(newValue);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <Stack>
                <Typography gutterBottom variant="caption" sx={{ color: 'text.primary' }}>
                  {translate('Kostnad lunch hemma').toUpperCase()}
                </Typography>
              </Stack>
              <PrettoSlider
                aria-label="Home lunch"
                value={homeLunchPrice}
                min={0}
                step={5}
                marks
                valueLabelDisplay="auto"
                max={100}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setHomeLunchPrice(newValue);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box>
              <Stack>
                <Typography gutterBottom variant="caption" sx={{ color: 'text.primary' }}>
                  {translate('Kostnad kaffe hemma').toUpperCase()}
                </Typography>
              </Stack>
              <PrettoSlider
                aria-label="Coffee home"
                value={homeCoffeeCost}
                min={0}
                step={5}
                marks
                valueLabelDisplay="auto"
                max={100}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setHomeCoffeeCost(newValue);
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box>
              <Stack>
                <Typography gutterBottom variant="caption" sx={{ color: 'text.primary' }}>
                  {translate('Bil').toUpperCase()}
                </Typography>
              </Stack>
              <PrettoSlider
                aria-label="Car shares"
                value={shareCar}
                min={0}
                step={5}
                marks
                valueLabelDisplay="auto"
                max={100}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setShareCar(newValue);
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box>
              <Stack>
                <Typography gutterBottom variant="caption" sx={{ color: 'text.primary' }}>
                  {translate('Kollektivt').toUpperCase()}
                </Typography>
              </Stack>
              <PrettoSlider
                aria-label="Public shares"
                value={sharePublic}
                min={0}
                step={5}
                marks
                valueLabelDisplay="auto"
                max={100}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setSharePublic(newValue);
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={3}>
            <Box>
              <Stack>
                <Typography gutterBottom variant="caption" sx={{ color: 'text.primary' }}>
                  {translate('Cykel').toUpperCase()}
                </Typography>
              </Stack>
              <PrettoSlider
                aria-label="Bike shares"
                value={shareBike}
                min={0}
                step={5}
                marks
                valueLabelDisplay="auto"
                max={100}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setShareBike(newValue);
                }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={3}>
            <Box>
              <Stack>
                <Typography gutterBottom variant="caption" sx={{ color: 'text.primary' }}>
                  {translate('Promenad').toUpperCase()}
                </Typography>
              </Stack>
              <PrettoSlider
                aria-label="Walk shares"
                value={shareWalk}
                min={0}
                step={5}
                marks
                valueLabelDisplay="auto"
                max={100}
                onChange={(event, newValue) => {
                  console.log(newValue);
                  setShareWalk(newValue);
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h4" sx={{ pt: 3, color: 'text.primary' }}>
              {translate('Individuella kostnader').toUpperCase()}
            </Typography>

            <Typography variant="h3" sx={{ color: 'text.primary' }}>
              {analysis.cost / employees > 1000 ? `${Math.round(analysis.cost / 100 / employees) / 10}tkr ` : `${analysis.cost / employees}kr`}
            </Typography>
            <Typography variant="h5" sx={{ pt: 3, color: 'text.secondary' }}>
              {translate('Besparingar/mån').toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h5" sx={{ color: 'text.primary' }}>
              {translate(`${wfh}% WFH`).toUpperCase()}
            </Typography>

            <Typography variant="h4" sx={{ color: 'success' }}>
              {analysis.savings / employees > 1000 ? `${Math.round(analysis.savings / employees / 100) / 10}tkr ` : `${analysis.savings / employees}kr`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h5" sx={{ color: 'text.disabled' }}>
              {translate('30% WFH').toUpperCase()}
            </Typography>

            <Typography variant="h4" sx={{ color: 'text.disabled' }}>
              {analysis.thirty / employees > 1000 ? `${Math.round(analysis.thirty / 100 / employees) / 10}tkr ` : `${analysis.thirty / employees}kr`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h5" sx={{ color: 'text.disabled' }}>
              {translate('50% WFH').toUpperCase()}
            </Typography>

            <Typography variant="h4" sx={{ color: 'text.disabled' }}>
              {analysis.fifty / employees > 1000 ? `${Math.round(analysis.fifty / 100 / employees) / 10}tkr ` : `${analysis.fifty / employees}kr`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h5" sx={{ color: 'text.disabled' }}>
              {translate('100% WFH').toUpperCase()}
            </Typography>

            <Typography variant="h4" sx={{ color: 'text.disabled' }}>
              {analysis.hundred / employees > 1000 ? `${Math.round(analysis.hundred / 100 / employees) / 10}tkr ` : `${analysis.hundred / employees}kr`}
            </Typography>
          </Grid>

          <Divider />

          <Grid item xs={12}>
            <Typography variant="h4" sx={{color: 'text.primary' }}>
              {translate('Summan av kostnader').toUpperCase()}
            </Typography>

            <Typography variant="h3" sx={{ color: 'text.primary' }}>
              {analysis.cost > 1000000 ? `${Math.round(analysis.cost / 100000) / 10}mkr ` : `${analysis.cost / 1000}tkr`}
            </Typography>
            <Typography variant="h5" sx={{ pt: 3, color: 'text.secondary' }}>
              {translate('Besparingar/mån').toUpperCase()}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h5" sx={{color: 'text.primary' }}>
              {translate(`${wfh}% WFH`).toUpperCase()}
            </Typography>

            <Typography variant="h4" sx={{ color: 'success' }}>
              {analysis.savings > 1000000 ? `${Math.round(analysis.savings / 100000) / 10}mkr ` : `${analysis.savings / 1000}tkr`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h5" sx={{ color: 'text.disabled' }}>
              {translate('30% WFH').toUpperCase()}
            </Typography>

            <Typography variant="h4" sx={{ color: 'text.disabled' }}>
              {analysis.thirty > 1000000 ? `${Math.round(analysis.thirty / 100000) / 10}mkr ` : `${analysis.thirty / 1000}tkr`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h5" sx={{color: 'text.disabled' }}>
              {translate('50% WFH').toUpperCase()}
            </Typography>

            <Typography variant="h4" sx={{ color: 'text.disabled' }}>
              {analysis.fifty > 1000000 ? `${Math.round(analysis.fifty / 100000) / 10}mkr ` : `${analysis.fifty / 1000}tkr`}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="h5" sx={{color: 'text.disabled' }}>
              {translate('100% WFH').toUpperCase()}
            </Typography>

            <Typography variant="h4" sx={{ color: 'text.disabled' }}>
              {analysis.hundred > 1000000 ? `${Math.round(analysis.hundred / 100000) / 10}mkr ` : `${analysis.hundred / 1000}tkr`}
            </Typography>
          </Grid>
        </Grid>
      </ContentStyle>
    </Container>
  );
}
