// ----------------------------------------------------------------------
export function prospectMagic(data) {
  const { prospect, workplaces, activityprofiles, workplacedistributions } = data;
  console.log("prospect magic started", prospect);
  // console.log('workplaces', workplaces);
  //  console.log('activities', activityprofiles);
  // console.log('workplacedistributions', workplacedistributions);
  /* 
  fullyRemoteAgreement
   */
  const { room_work_area, screened_work_area, open_work_area, annual_rent, work_area, work_model, sqm, employees, growth, remotePolicy, spaceFormula, percentageFormula, occupancy, openWorkArea, otherArea, qtFullyRemote, secondWord, smallRooms, socialArea, firstWord, individualRooms, largeRooms, lectureRooms, thirdWord } = prospect;

  const percentageFactor = spaceFormula === "Always for everyone" ? 1 : spaceFormula === "Percentage of total workforce" ? percentageFormula / 100 : work_model;
  const netEmployees = employees * (work_model) / 100 * (100 + (growth || 0)) / 100;
  console.log('net employees', employees, netEmployees);
  const activityBase = activityprofiles;

  // FIND HYBRID ANSWERS MY OWN OFFICE
  const activityCategories = [
    "Individual",
    "Collaboration small",
    "Collaboration large",
    "Other",
  ];

  const activityCalculation = activityBase.map((a) => {
    // DO WORKPLACE MAPPING
    const activityShare = prospect[`act_${a.tag}`] || 0;

    // TODO: N/A MAPPING
    //console.log('workplaceData', workplaceData, a, submissions.workplaceAnswers);
    const officeShare = prospect[`adist_${a.tag}`] || 0;
    // console.log('activity share', activityShare, officeShare);
    const activityWorkplaceShare = (activityShare * officeShare) / 100;

    // DO SOUND MAPPING
    if (officeShare === 0) return { ...a, activityShare, officeShare: 0, activityShareAtOffice: 0 };

    const obj = {
      ...a,
      activityShare,
      officeShare,
      activityShareAtOffice: activityWorkplaceShare,
    };
    // console.log('obj', obj);
    return obj;
  });
  // console.log('activityCalculation', activityCalculation);
  // console.log('activityCalculation', activityCalculation.filter((a) => a !== null));
  // OFFICE ACTIVITY PROFILE AND ADJUST FOR 100%  (OFFICE ACTIVITY PROFILE)
  const officeActivityProfile = activityCalculation.map((a) => {
    const openShare = prospect[`open_${a.tag}`] || 0;
    const roomShare = prospect[`room_${a.tag}`] || 0;
    const screenedShare = prospect[`screened_${a.tag}`] || 0;
    // console.log('openShare', openShare);
    const preferredSoundEnvironment =
      roomShare > screenedShare && roomShare > openShare
        ? "Room"
        : screenedShare > roomShare && screenedShare > openShare
          ? "Screened"
          : "Open";
    // NORMALIZE ACTIVITY SHARES TO MATCH 100% OF WORKPLACE SHARES
    const officeActivityProfile =
      (100 * a.activityShareAtOffice) /
      activityCalculation.filter((a) => a !== null).reduce(
        (acc, curr) => acc + curr.activityShareAtOffice,
        0
      );

    const shareOfTotalRoom = (officeActivityProfile * roomShare) / 100;
    const shareOfTotalScreeened =
      (officeActivityProfile * screenedShare) / 100;
    const shareOfTotalOpen = (officeActivityProfile * openShare) / 100;
    const obj = {
      ...a,
      officeActivityProfile,
      preferredSoundEnvironment,
      room: {
        share: roomShare,
        shareOfTotal: shareOfTotalRoom,
      },
      open: {
        share: openShare,
        shareOfTotal: shareOfTotalOpen,
      },
      screened: {
        share: screenedShare,
        shareOfTotal: shareOfTotalScreeened,
      },
    };
    //console.log('ROOM SAR', obj);
    return obj;
  });
  // console.log('officeActivityProfile', officeActivityProfile);
  //USE SHARES OF TOTAL AND CALCULATE ACTIVITY PROFILES FOR EACH ROOM TYPE ("OFFICE LAYOUT")
  const activityRoomNormalized = officeActivityProfile.map((a) => {

    // ROOM
    const sumShareOfTotalRoom = officeActivityProfile.reduce(
      (acc, curr) => acc + curr.room.shareOfTotal,
      0
    ) || 0;
    const roomActivityProfile =
      (a.room.shareOfTotal / sumShareOfTotalRoom) * 100 || 0;
    // OPEN
    const sumShareOfTotalOpen = officeActivityProfile.reduce(
      (acc, curr) => acc + curr.open.shareOfTotal,
      0
    ) || 0;

    const openActivityProfile =
      (a.open.shareOfTotal / sumShareOfTotalOpen) * 100 || 0;
    // SCREENED
    const sumShareOfTotalScreened = officeActivityProfile.reduce(
      (acc, curr) => acc + curr.screened.shareOfTotal,
      0
    ) || 0;
    const screenedActivityProfile =
      (a.screened.shareOfTotal / sumShareOfTotalScreened) * 100 || 0;

    //CREATE OBJECT AND RETURN
    const obj = {
      ...a,
      room: {
        ...a.room,
        activityProfile: roomActivityProfile,
      },
      open: {
        ...a.open,
        activityProfile: openActivityProfile,
      },
      screened: {
        ...a.screened,
        activityProfile: screenedActivityProfile,
      }
    };
    return obj;
  });
  //console.log('activityRoomNormalized', activityRoomNormalized);
  const workplaceSums = workplaces.map((workplace) => {
    const roomtype = workplace.soundenv;
    //console.log('workplace,', workplace);
    const activitySums = workplace.workplaceprofiles.map((activityProfile) => {
      const activity = activityRoomNormalized.find((a) => a.id === activityProfile.activityprofile_id);
      if (!activity) return null;
      // console.log('activity', activity, activityProfile);
      if (roomtype === "Screened") {
        const activitySum = activity?.screened.activityProfile * activityProfile.share;
        return activitySum;
      } else if (roomtype === "Open") {
        const activitySum = activity?.open.activityProfile * activityProfile.share;
        return activitySum;
      } else {
        const activitySum = activity?.room.activityProfile * activityProfile.share;
        return activitySum;
      }
    })
    const shareOfTotal = activitySums.reduce((acc, curr) => acc + curr, 0);
    //console.log('activitysums', activitySums, netEmployees, percentageFactor, shareOfTotal);
    const fte = netEmployees * percentageFactor * shareOfTotal / 100 / 100;
    const units = fte / workplace.ppl;
    const unitsRoundedUp = Math.ceil(units);
    const sqm = unitsRoundedUp * workplace.size;
    const isErgonomic = workplace.is_ergonomic
    const isSmallRoom = workplace.ppl < 5 && workplace.soundenv === "Room" && workplace.ppl > 1;
    const isLargeRoom = workplace.ppl < 12 && workplace.soundenv === "Room" && workplace.ppl > 4;
    const obj = {
      ...workplace,
      fte,
      units,
      unitsRoundedUp,
      sqm,
      isSmallRoom,
      isLargeRoom,
      isErgonomic,
    };
    return obj;
  })
  console.log('CHECK HERE', workplaceSums);
  const workplaceSumsTotal = workplaceSums.reduce((acc, curr) => acc + curr.sqm, 0);
  // ROOMS -----------------------------------------------------

  const roomAggregates = ["Room", "Screened", "Open"].map((room) => {
    let output = {};
    if (room === "Room") {
      const normalizedShare = activityRoomNormalized.reduce(
        (acc, curr) => acc + curr.room.shareOfTotal,
        0
      );
      const inefficientSpace = workplaceSums.filter((wp) => wp.soundenv === "Room").reduce((acc, curr) => acc + curr.sqm, 0);
      const innefficientShare = (100 * inefficientSpace) / workplaceSumsTotal;
      output = { room, normalizedShare, innefficientShare };
    } else if (room === "Screened") {
      const normalizedShare = activityRoomNormalized.reduce(
        (acc, curr) => acc + curr.screened.shareOfTotal,
        0
      );
      const inefficientSpace = workplaceSums.filter((wp) => wp.soundenv === "Screened").reduce((acc, curr) => acc + curr.sqm, 0) || 0;
      const innefficientShare = (100 * inefficientSpace) / workplaceSumsTotal;
      output = { room, normalizedShare, innefficientShare };
    } else if (room === "Open") {
      const normalizedShare = activityRoomNormalized.reduce(
        (acc, curr) => acc + curr.open.shareOfTotal,
        0
      );
      const inefficientSpace = workplaceSums.filter((wp) => wp.soundenv === "Open").reduce((acc, curr) => acc + curr.sqm, 0);
      const innefficientShare = (100 * inefficientSpace) / workplaceSumsTotal;
      output = { room, normalizedShare, innefficientShare };
    }
    return output;
  });

  // console.log("roomaggregates", roomAggregates);
  /* 
  const activityProfileCategories = activityCategories.map((cat) => {
    const answers = activityBase.filter((a) => a.category === cat);
    const categorySum = answers.reduce((acc, curr) => acc + curr.average, 0);
    const categoryAvg = categorySum / answers.length;
    return {
      category: cat,
      answers,
      categorySum,
      categoryAvg,
    };
  }); */

  const categoryProfiles = activityCategories.map((cat) => {
    const questions = officeActivityProfile.filter((a) => a.category === cat);
    const categorySum = questions.reduce(
      (acc, curr) => acc + curr.normalizedShare,
      0
    );
    const categoryAvg = categorySum / questions.length;
    return {
      category: cat,
      categorySum,
      categoryAvg,
    };
  });

  // console.log("normalized categories", categoryProfiles);
 // console.log('got to end');
  const workplaceSqm = workplaceSumsTotal;
  const sqmNeed = workplaceSqm / (work_area / 100);
  const savings = sqm - sqmNeed;
  const savingsPotential = savings / sqm * 100;
  const ergonomicNeedCapacity = workplaceSums.filter((wp) => wp.isErgonomic).reduce((acc, curr) => acc + (curr.unitsRoundedUp * curr.ppl), 0);
  const totalNeedCapacity = workplaceSums.reduce((acc, curr) => acc + curr.unitsRoundedUp * curr.ppl, 0);
  const ergonomicRatio = Math.round(ergonomicNeedCapacity / totalNeedCapacity * 100);
  const openNeedCapacity = workplaceSums.filter((wp) => wp.soundenv === "Open").reduce((acc, curr) => acc + curr.sqm, 0);
  const openRatio = Math.round(openNeedCapacity / workplaceSqm * 100);
  const roomNeedCapacity = workplaceSums.filter((wp) => wp.soundenv === "Room").reduce((acc, curr) => acc + curr.sqm, 0);
  const roomRatio = Math.round(roomNeedCapacity / workplaceSqm * 100);
  const screenedNeedCapacity = workplaceSums.filter((wp) => wp.soundenv === "Screened").reduce((acc, curr) => acc + curr.sqm, 0);
  const screenedRatio = Math.round(screenedNeedCapacity / workplaceSqm * 100);
  const openArea = sqm * (open_work_area / 100) * (work_area / 100);
  const roomArea = sqm * (room_work_area / 100) * (work_area / 100);
  const screenedArea = sqm * (screened_work_area / 100) * (work_area / 100);
  const openDiff = openArea - openNeedCapacity;
  const roomDiff = roomArea - roomNeedCapacity;
  const screenedDiff = screenedArea - screenedNeedCapacity;
  const openScore = Math.round(openDiff / openArea * 100);
  const roomScore = Math.round(roomDiff / roomArea * 100);
  const screenedScore = Math.round(screenedDiff / screenedArea * 100);

  const openShareDiff = (open_work_area) - openRatio;
  const roomShareDiff = (room_work_area) - roomRatio;
  const screenedShareDiff = (screened_work_area) - screenedRatio;
  const recommendedWorkModel = officeActivityProfile.reduce((acc, curr) => acc + curr.activityShareAtOffice, 0);
  return {
    officeActivityProfile,
    categoryProfiles,
    sqmNeed,
    workplaceSums,
    sqm,
    employees,
    percentageFormula,
    spaceFormula,
    remotePolicy,
    work_model,
    growth,
    occupancy,
    openWorkArea,
    otherArea,
    qtFullyRemote,
    secondWord,
    smallRooms,
    socialArea,
    firstWord,
    individualRooms,
    largeRooms,
    lectureRooms,
    thirdWord,
    sqmRent: Math.round(annual_rent / sqm) || 0,
    savings,
    savingsPotential,
    workplaceSqm,
    ergonomicRatio,
    recommendedWorkModel,
    room: {
      areaScore: roomScore,
      needShare: roomRatio,
      shareDiff: roomShareDiff,
      areaDiff: roomDiff,
      area: roomArea,
      needArea: roomNeedCapacity,
      share: room_work_area,
    },
    open: {
      areaScore: openScore,
      needShare: openRatio,
      shareDiff: openShareDiff,
      areaDiff: openDiff,
      area: openArea,
      needArea: openNeedCapacity,
      share: open_work_area,
    },
    screened: {
      areaScore: screenedScore,
      needShare: screenedRatio,
      shareDiff: screenedShareDiff,
      areaDiff: screenedDiff,
      area: screenedArea,
      needArea: screenedNeedCapacity,
      share: screened_work_area,
    },
  };
}

