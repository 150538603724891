import { LoadingButton } from '@mui/lab';
import { Alert, Box, Grid, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Geocode from 'react-geocode';
import * as Yup from 'yup';
import { geoConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';
import fakeRequest from '../../../utils/fakeRequest';
import DistanceMap from './DistanceMap';

// ----------------------------------------------------------------------

Geocode.setApiKey(geoConfig);

// ----------------------------------------------------------------------
NewPlaceForm.propTypes = {
  onPlace: PropTypes.func
};

export default function NewPlaceForm({ onPlace, originsArray, place, output }) {
  const theme = useTheme();
  
  const { translate } = useLocales();
  const [lng, setLng] = useState(0.0);
  const [lat, setLat] = useState(0.0);
  const [polygons] = useState([]);
  const [geojson, setGeojson] = useState([]);
  const [enhancedArray, setEnhancedArray] = useState([]);
  const [indx] = useState(0);
  const [name, setName] = useState('');
  const [openSnack, setOpenSnack] = useState(false);
  const [complete, setComplete] = useState(false);
  const [zip, setZip] = useState('');
  const [county, setCounty] = useState('');
  const [country, setCountry] = useState('');
  const [enhancedLoaded, setEnhancedLoaded] = useState(false);

  useEffect(() => {
    if (!output.length || enhancedLoaded) return;
    console.log('output address', output);
    let aa = [];
    const ehancedOrigins = output.map((o) => {
      const { address, city } = o;
      (async function () {
        const books = await getCoordinates(o);
        console.log(books);
        aa.push(books);
        console.log(aa);
        if (aa.length === originsArray.length) {
          setComplete(true);
          setEnhancedArray(aa);
          setEnhancedLoaded(true);
        }
      })();
    });
  }, [output]);
  useEffect(() => {
    if (!place) return;
    console.log('place', place);
    handleName(place.address, place.city_name)
  }, [place]);
  async function getCoordinates(origin) {
    try {
      const { address, city } = origin;
      const enhan = await Geocode.fromAddress(address + '%20' + city)
        .then((response) => response.results[0].geometry.location)
      // GET information about each boo
      console.log(enhan);
      const full = {
        ...origin,
        ...enhan,
        durationValue: Math.round(origin.duration?.value / 60, 0),
        distanceValue: Math.round(origin.distance?.value / 1000, 1)
      };
      return full;
    } catch (error) {
      // If any of the awaited promises was rejected, this catch block
      // would catch the rejection reason
      return error;
    }
  }

  const handleMarkerDragged = (lngat) => {
    setLng(lngat.lng);
    setLat(lngat.lat);
  };

  const handleName = (name, city) => {
    if (!name || city.length < 2) return;
    Geocode.fromAddress(name + city)
      .then((response) => {
        const addcomp = response.results[0].address_components;
        const countrytrial = addcomp.filter((comp) => comp.types[0] === 'country');
        const zip = addcomp.filter((comp) => comp.types[0] === 'postal_code');
        const countytrial = addcomp.filter((comp) => comp.types[0] === 'administrative_area_level_1');
        setCounty(countytrial[0].long_name);
        setZip(zip[0].long_name.replace(/ /g, ''));
        setCountry(countrytrial[0].long_name);
        const { lat, lng } = response.results[0].geometry.location;
        setLng(lng);
        setLat(lat);
        console.log(lng, lat);
      })
      .catch(() => setOpenSnack(true));
  };

  const NewPlaceSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    city: Yup.string().required('City is required')
  });

  const formik = useFormik({
    initialValues: {
      title: '',
      city: '',
    },
    validationSchema: NewPlaceSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await fakeRequest(500);
        resetForm();
        setSubmitting(false);
        enqueueSnackbar('Post success', { variant: 'success' });
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  const { errors, values, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleBlur = (e) => {
    if (values.title && values.city.length > 2) {
      setName(values.title);
      handleName(values.title, values.city);
      if (e.keyCode === 13) {
        handleClick();
      }
    }
  };
  const handleClick = () => {
    const place = {
      name: values.title,
      city: values.city,
      zip,
      lat,
      lng,
      country,
      county,
    };
    onPlace(place);
  };

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  return (
    <>
      <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="error" sx={{ width: '100%' }}>
          {translate('noplace')}
        </Alert>
      </Snackbar>
      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={12}>
              {!place ? (
                <>
                  <Box sx={{ p: 3 }}>
                    <Grid container spacing={3} sx={{ alignItems: 'center' }}>
                      <Grid item xs={12} md={5}>
                        <TextField
                          fullWidth
                          label={translate('name')}
                          onKeyUp={handleBlur}
                          {...getFieldProps('title')}
                          error={Boolean(touched.title && errors.title)}
                          helperText={touched.title && errors.title}
                        />
                      </Grid>
                      <Grid item xs={12} md={5}>
                        <TextField
                          fullWidth
                          label={translate('city')}
                          onKeyUp={handleBlur}
                          {...getFieldProps('city')}
                          error={Boolean(touched.city && errors.city)}
                          helperText={touched.city && errors.city}
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        {name ? (
                          <Stack direction="row" spacing={3}>
                            <LoadingButton onClick={handleClick} variant="contained" size="large" loading={isSubmitting}>
                              {translate('Save')}
                            </LoadingButton>
                          </Stack>
                        ) : (
                          <LoadingButton
                            fullWidth
                            onClick={handleBlur}
                            variant="outlined"
                            size="large"
                            loading={isSubmitting}
                          >
                            {translate('search')}
                          </LoadingButton>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </>
              ) : null}
              <div>
                <Box sx={{ height: '70vh' }}>
                  <DistanceMap
                    coords={[lng, lat]}
                    drawn={values.drawn}
                    geojsonpoly={geojson}
                    origins={enhancedArray}
                  />
                </Box>
              </div>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
}
