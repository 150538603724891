import { Icon } from '@iconify/react';
import SvgIconStyle from '../../components/SvgIconStyle';
import { PATH_DASHBOARD } from '../../routes/paths';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  team: getIcon('people'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  home: <Icon icon="ic:baseline-business-center" width={'100%'} height={'100%'} />,
  build: <Icon icon="ic:baseline-handyman" width={'100%'} height={'100%'} />,
  road: <Icon icon="mingcute:road-line" width={'100%'} height={'100%'} />,
  homme: <Icon icon="material-symbols:home" width={'100%'} height={'100%'} />,
  place: getIcon('stop-circle-outline'),
  space: getIcon('square-outline'),
  flow: <Icon icon="fluent:arrow-flow-up-right-32-filled" width={'100%'} height={'100%'}/>,
  property: getIcon('grid-outline'),
  overview: <Icon icon="oui:integration-general" width={'100%'} height={'100%'}/>,
  layers: getIcon('layers-outline'),
  info: getIcon('info-outline'),
  money: <Icon icon="solar:money-bag-linear" width={'100%'} height={'100%'}/>,
  weather: <Icon icon="fluent:weather-haze-20-regular" width={'100%'} height={'100%'}/>
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'plats',
    items: [
      { title: 'overview', path: PATH_DASHBOARD.dashboard.overview, icon: ICONS.layers },
      { title: 'data inputs', path: PATH_DASHBOARD.dashboard.datainput, icon: ICONS.place },
      { title: 'review', path: PATH_DASHBOARD.dashboard.review, icon: ICONS.team },
      { title: 'recommendation', path: PATH_DASHBOARD.dashboard.recommendation, icon: ICONS.flow },
      { title: 'simulation', path: PATH_DASHBOARD.dashboard.simulation, icon: ICONS.analytics },
      { title: 'solution', path: PATH_DASHBOARD.dashboard.solution, icon: ICONS.place },
      { title: 'follow-up', path: PATH_DASHBOARD.dashboard.followup, icon: ICONS.calendar },
      { title: 'settings', path: PATH_DASHBOARD.dashboard.settings, icon: ICONS.build },
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  /*
  {
    subheader: 'admin',
    items: [
      // MANAGEMENT : USER

      { title: 'ledning', path: PATH_DASHBOARD.neo.management, icon: ICONS.user },
      { title: 'medarbetare', path: PATH_DASHBOARD.neo.employees, icon: ICONS.team },
    ]
  } */
];

export default sidebarConfig;
