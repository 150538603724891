import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { Icon } from '@iconify/react';
import { Download, IosShare, Lock, LockOpen } from '@mui/icons-material';
import { AppBar, Box, Button, Divider, FormControlLabel, FormGroup, IconButton, Stack, Switch, Toolbar, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { MHidden } from '../../components/@material-extend';
import SettingModeMenu from '../../components/settings/SettingModeMenu';
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useOffSetTop from '../../hooks/useOffSetTop';
import { getCompany } from '../../redux/slices/company';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import { onToggleImpact, onToggleInput, onToggleRecommendation, onToggleSolution } from '../../redux/slices/user';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;
const HEADERSBRUTTO = [
  { label: "Id", key: "id" },
  { label: "Name", key: "name" },
  { label: "Flöden", key: "visitors" },
];
const HEADERS = [
  { label: "Id", key: "id" },
  { label: "site_id", key: "site_id" },
  { label: "Name", key: "name" },
  { label: "Visitors Average", key: "visitors_average" },
  { label: "Visitors Direct", key: "visitors_direct" },
  { label: "Working %", key: "working_percentage" },
  { label: "Home %", key: "home_percentage" },
  { label: "Other %", key: "other_percentage" },
  { label: "Population 10 min walk", key: "pop_10_walk" },
  { label: "Population 15 min walk", key: "pop_15_walk" },
  { label: "Population 10 min drive", key: "pop_10_drive" },
  { label: "Population 15 min drive", key: "pop_15_drive" },
  { label: "Competition 10 min walk", key: "comp_10_walk" },
  { label: "Competition 15 min walk", key: "comp_15_walk" },
  { label: "Competition 10 min drive", key: "comp_10_drive" },
  { label: "Competition 15 min drive", key: "comp_15_drive" },
];
const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const { isCollapse } = useCollapseDrawer();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const isOffset = useOffSetTop(100);
  const { pathname } = useLocation();
  const [isExport, setIsExport] = useState(false);
  const isHome = pathname === '/';
  const [text, setText] = useState(null);
  const [readMode, setReadMode] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  console.log('read', readMode);
  const { isInput, isRecommend, isSolution, isImpact } = useSelector((state) => state.user);
  const { company } = useSelector((state) => state.company);
  useEffect(() => {
    if (!user) return
    dispatch(getCompany(user.companyHubspot));
  }, [dispatch, user]);
  const handlePopoverOpenE = (event) => {
    setAnchorEl(event.currentTarget);
    setText('Exportera');
  }

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  function updateCompany() {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/companies/update/${company.id}`;
    const counterNew = company.counter_traffic + 1;
    axios.put(url, {
      id: company.id,
      counter_traffic: counterNew
    })
      .then(() => {
        console.log('UPDATED');
        setOpenDialog(false);
      })
      .catch((error) => console.log(error.message));
  }
  const openn = Boolean(anchorEl);
  const handlread = () => {
    const newread = !readMode;
    console.log('f');
    setReadMode(newread)
  };
  
  return (
    <RootStyle
      sx={{
        ...(isCollapse && {
          width: { md: `calc(100% - ${COLLAPSE_WIDTH}px)` }
        })
      }}
    >
      <ToolbarStyle>
        <MHidden width="mdUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>
        <Divider orientation="vertical" flexItem />

        <Divider orientation="vertical" flexItem />
        {
          //<LogoHori />
        }
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>


          {!isExport && (
            <IconButton
              onClick={() => setIsExport(true)}
              aria-owns={openn ? 'export-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpenE}
              onMouseLeave={handlePopoverClose}
            >
              <IosShare />
            </IconButton>
          )}
          <LanguagePopover />
          <SettingModeMenu isOffset={isOffset} isHome={isHome} />
          <Box sx={{ py: 2, ml: 2 }}>
            <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
              {user.displayName}
            </Typography>
            <Typography variant="caption" sx={{ color: 'text.secondary' }}>
              {user?.companyName}
            </Typography>
          </Box>
          <AccountPopover company={company} />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
//<JourneyPopover />