/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../components/SoftBox";
import SoftTypography from "../../../components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import Footer from "../../../examples/Footer";
import DataTable from "../../../examples/Tables/DataTable";

// ProductPage page components
import ProductImages from "./components/ProductImages";
import ProductInfo from "./components/WorkplaceInfo";

// Data
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from '../../../redux/store';
import { getActivityprofiles, getWorkplace, getWorkplaceprofiles, getWorkplaces } from "../../../redux/slices/workplace";
import LoadingScreen from "../../../components/LoadingScreen";
import ProductCell from "./components/ProductCell";
import blackChair from "../../../assets/images/ecommerce/black-chair.jpeg";
import chairPink from "../../../assets/images/ecommerce/chair-pink.jpeg";
import chairSteel from "../../../assets/images/ecommerce/chair-steel.jpeg";
import chairWood from "../../../assets/images/ecommerce/chair-wood.jpeg";
import DefaultCell from "./components/DefaultCell";
import ReviewCell from "./components/ReviewCell";
import SoftProgress from "../../../components/SoftProgress";

function ProductPage() {
  const dispatch = useDispatch();
  const { id } = useParams();

  const { activityprofiles, workplaces, workplaceprofiles, groupedworkplaces, workplace } = useSelector((state) => state.workplace);
  const [finalProfiles, setFinalProfiles] = useState([]);
  const [finalProfile, setFinalProfile] = useState('');
  const [data, setData] = useState([]);

  useEffect(() => {
    if (!activityprofiles.length) {
      dispatch(getActivityprofiles());
    };
    if (!workplaceprofiles.length) {
      dispatch(getWorkplaceprofiles());
    };
  }, [dispatch]);

  useEffect(() => {
    console.log('id', id);
    if (id) {
      dispatch(getWorkplace(id));
    };
  }, [dispatch, id]);

  useEffect(() => {
    if (!activityprofiles.length || !workplaces.length || !workplaceprofiles.length || !groupedworkplaces.length || !workplace) return;
    const newgroups = groupedworkplaces.map((group, groupidx) => {
      const zone = group.zone
      const workplacez = group.workplaces.map((wp) => {
        const name = wp.name;
        const workplaceprofiles = wp.workplaceprofiles.filter((wpp) => wpp.share !== 0).map((wppz) => {
          const profile = activityprofiles.find((a) => (a.id === wppz.activityprofile_id));

          let roomshare = 0.0

          if (groupidx === 0) {
            roomshare = profile?.soundRoom;
          } else if (groupidx === 1) {
            roomshare = profile?.soundOpen;
          } else {
            roomshare = profile?.soundSheltered;
          }
          const activityprofileShareOfTotal = Math.round(1000 * wppz.share * roomshare * profile?.weightedShare, 1) / 10;
          const wpprofile = { ...wppz, profile, roomshare, activityprofileShareOfTotal };
          return wpprofile;
        });
        const workplaceSum = workplaceprofiles.reduce((a, v) => (a += v.activityprofileShareOfTotal), 0);
        const workpl = { ...wp, workplaceprofiles, workplaceSum };
        return workpl;
      });
      const groupSum = workplacez.reduce((a, v) => (a += v.workplaceSum), 0);
      const groupobj = { ...group, workplacez, groupSum };
      return groupobj;
    });
    console.log('new GROUPS', newgroups);
    setFinalProfiles(newgroups);
    console.log('workplace', workplace, newgroups[workplace?.sound_id - 1]);

    console.log('ld', newgroups[workplace?.sound_id - 1].workplacez.find((p) => p.id === workplace.id));

    const finalPr = newgroups[workplace?.sound_id - 1].workplacez.find((p) => p.id === workplace.id);
    setFinalProfile(finalPr);
    const rowz = finalPr.workplaceprofiles.map((r) => {
      const row = {
        product: <ProductCell image={blackChair} name={r.profile.name} />,
        equivalents: <DefaultCell>{Math.round(r.profile.equivalents)}</DefaultCell>,
        review: <ReviewCell rating={4.5} />,
        availability: (
          <SoftBox width="8rem">
            <SoftProgress variant="gradient" value={r.roomshare * 100} color="success" />
          </SoftBox>
        ),
        availabilitytotal: (
          <SoftBox width="8rem">
            <SoftProgress variant="gradient" value={r.activityprofileShareOfTotal} color="success" />
          </SoftBox>
        ),
        id: <DefaultCell>230019</DefaultCell>,
      }
      return row;
    });
    const dataTableData = {
      columns: [
        { Header: "name", accessor: "product", width: "50%" },
        { Header: "equivalents", accessor: "equivalents", width: "10%" },
        { Header: "review", accessor: "review", align: "center" },
        { Header: "share", accessor: "availability", align: "center", width: "40%" },
        { Header: "share of total", accessor: "availabilitytotal", align: "center", width: "40%" }

      ],

      rows: rowz
    }
    setData(dataTableData);
  }, [activityprofiles, workplaces, workplaceprofiles, groupedworkplaces, workplace]);

  if (!workplace || !finalProfiles.length) return <LoadingScreen />
  return (
    <DashboardLayout>
      <SoftBox py={3}>
        <Card sx={{ overflow: "visible" }}>
          <SoftBox p={3}>
            <SoftBox mb={3}>
              <SoftTypography variant="h5" fontWeight="medium">
                Workplace Details
              </SoftTypography>
            </SoftBox>

            <Grid container spacing={3}>
              <Grid item xs={12} lg={6} xl={5}>
                <ProductImages />
              </Grid>
              <Grid item xs={12} lg={5} sx={{ mx: "auto" }}>
                <ProductInfo workplace={workplace} activityprofiles={[]} />
              </Grid>
            </Grid>

            <SoftBox mt={8} mb={2}>
              <SoftBox mb={1} ml={2}>
                <SoftTypography variant="h5" fontWeight="medium">
                  Activities
                </SoftTypography>
              </SoftBox>
              <DataTable
                table={data}
                entriesPerPage={false}
                showTotalEntries={false}
                isSorted={false}
              />
            </SoftBox>
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductPage;
