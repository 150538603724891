// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '';
const ROOTS_API = 'https://plejsapp-api.herokuapp.com/api/v1';

// ----------------------------------------------------------------------

export const PATH_API = {
  root: ROOTS_API,
  spaces: path(ROOTS_API, '/spaces/index'),
  places: path(ROOTS_API, '/places/index'),
  placesrecent: path(ROOTS_API, '/places/recent')
};
export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  home: '/',
  offer: '/offer',
  plejs: '/plejs',
  landing: '/landing',
  howfar: '/howfar',
  consumtionsimulator: '/consumtionsimulator',
  login: '/login',
  interest: '/interest/new',
  references: '/references',
  about: '/about-us',
  contact: '/contact-us',
  page404: '/404',
  page500: '/500',
  comingsoon: '/comingsoon',
  blog: {
    root: '/blog/posts',
    posts: '/blog/posts',
    post: '/blog/post/',
    newPost: '/blog/new-post'
  }
};

export const PATH_DASHBOARD = {
  root: path(ROOTS_DASHBOARD, '/app'),
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
  },
  dashboard: {
    root: path(ROOTS_DASHBOARD, '/dashboard/dashboard'),
    dashboard: path(ROOTS_DASHBOARD, '/dashboard/dashboard'),
    solution: path(ROOTS_DASHBOARD, '/dashboard/solution'),
    recommendation: path(ROOTS_DASHBOARD, '/dashboard/recommendation'),
    simulation: path(ROOTS_DASHBOARD, '/dashboard/simulation'),
    review: path(ROOTS_DASHBOARD, '/dashboard/review'),
    followup: path(ROOTS_DASHBOARD, '/dashboard/followup'),
    settings: path(ROOTS_DASHBOARD, '/dashboard/settings'),
    datainput: path(ROOTS_DASHBOARD, '/dashboard/datainput'),
    overview: path(ROOTS_DASHBOARD, '/dashboard/overview'),
  },
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, `/user/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  workplaces: {
    root: path(ROOTS_DASHBOARD, '/workplaces/list'),
    list: path(ROOTS_DASHBOARD, '/workplaces/list')
  },
  survey: {
    root: path(ROOTS_DASHBOARD, '/surveys'),
    survey: path(ROOTS_DASHBOARD, '/surveys'),
    surveys: path(ROOTS_DASHBOARD, '/surveys'),
    response: path(ROOTS_DASHBOARD, '/surveys/response'),
    list: path(ROOTS_DASHBOARD, '/surveys')
  },
  activities: {
    root: path(ROOTS_DASHBOARD, '/activities/list'),
    list: path(ROOTS_DASHBOARD, '/activities/list')
  },
  distance: {
    root: path(ROOTS_DASHBOARD, '/distance/wiz'),
    wiz: path(ROOTS_DASHBOARD, '/distance/wiz')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
