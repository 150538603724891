/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";
import SoftTypography from "../../../components/SoftTypography";

// Soft UI Dashboard PRO React example components
import Footer from "../../../examples/Footer";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DataTable from "../../../examples/Tables/DataTable";

// Data
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SoftBadge from "../../../components/SoftBadge";
import { getWorkplaces } from "../../../redux/slices/workplace";
import ActionCell from "./components/ActionCell";

function WorkplaceList() {

  const dispatch = useDispatch();
  const { workplaces } = useSelector((state) => state.workplace);
  const [modprf, setModprf] = useState(workplaces);
  useEffect(() => {
    if (!workplaces.length) {
      dispatch(getWorkplaces());
    };
  }, [dispatch]);

  useEffect(() => {
    if (!workplaces.length) return;
    const modprofiles = workplaces.map((a) => {
      const pr = { ...a, action: <ActionCell />, route: `/workplaces/${a.id}` };
      return pr;
    })
    setModprf(modprofiles);
  }, [workplaces]);
  console.log('workplaces, ', workplaces);
  const outOfStock = (
    <SoftBadge variant="contained" color="error" size="xs" badgeContent="not ergonomic" container />
  );
  const inStock = (
    <SoftBadge variant="contained" color="success" size="xs" badgeContent="ergonomic" container />
  );

  const dataTableData = {
    columns: [
      { Header: "name", accessor: "name" },
      { Header: "soundenv", accessor: "soundenv" },
      { Header: "functionality", accessor: "functionality" },
      { Header: "other functionality", accessor: "functionalityExtra" },
      {
        Header: "ergonomic",
        accessor: "is_ergonomic",
        Cell: ({ value }) => (value === true ? inStock : outOfStock),
      },
      { Header: "action", accessor: "action" },
    ],
    rows: modprf
  };

  return (
    <DashboardLayout>
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Work Settings Directory
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Descriptions to better explain the properties of each work seting.
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction="row">{/* 
              <Link to="/ecommerce/products/new-product">
                <SoftButton variant="gradient" color="info" size="small">
                  + new workplace
                </SoftButton>
              </Link> */}
              <SoftButton variant="outlined" color="info" size="small">
                import
              </SoftButton>
              <SoftButton variant="outlined" color="info" size="small">
                export
              </SoftButton>
            </Stack>
          </SoftBox>
          <DataTable
            table={dataTableData}
            entriesPerPage={{
              defaultValue: 10,
              entries: [5, 7, 10, 15, 20, 25],
            }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default WorkplaceList;
