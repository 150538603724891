import { LoadingButton } from '@mui/lab';
import {
  Alert, Card, Grid,
  Snackbar,
  Stack, Typography
} from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import Geocode from 'react-geocode';
import * as Yup from 'yup';
import { geoConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';
import LoadingScreen from '../../LoadingScreen';
import SoftBox from '../../SoftBox';
import SoftInput from '../../SoftInput';
import SoftSelect from '../../SoftSelect';
import SoftTypography from '../../SoftTypography';

// ----------------------------------------------------------------------

Geocode.setApiKey(geoConfig);
const DENSITY_OPTION = ['Low', 'Mid', 'High'].map((option) => ({ value: option, label: option }));
const TAGS_OPTION = ['Completed', 'Ongoing', 'Incomplete'].map((option) => ({ value: option, label: option }));
const WFH_OPTIONS = ['Peak', 'Even', '75%'].map((option) => ({ value: option, label: option }));

// ----------------------------------------------------------------------

export default function CompanyForm({ tenant }) {

  const { translate } = useLocales();

  const [zip, setZip] = useState('');
  const [country, setCountry] = useState('');
  const [openSnack, setOpenSnack] = useState(false);

  const handleCloseSnack = () => {
    setOpenSnack(false);
  };

  const NewMissionSchema = Yup.object().shape({
    name: Yup.string().required('Name is required')
  });

  const formik = useFormik({
    initialValues: { ...tenant },
    validationSchema: NewMissionSchema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        await updateFields(values);
        setSubmitting(false);
        enqueueSnackbar('Post success', { variant: 'success' });
      } catch (error) {
        console.error(error);
        setSubmitting(false);
      }
    }
  });

  if (!tenant) return <LoadingScreen />;
  const { errors, values, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formik;

  console.log(tenant);
  const updateFields = () => {
    console.log(values);
    console.log(tenant);
    const url = `https://plejsapp-api.herokuapp.com/api/v1/companies/update/${tenant?.id}`;

    if (values.name.length === 0 || values.id.length === 0) return;
    const body = values;
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(body)
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        }
        throw new Error('Network response was not ok.');
      })

      .then((response) => {
        console.log('UPDATED');
        setOpenSnack(true);
      })
      .catch((error) => console.log(error.message));
  };

  function handleEdi() {
    console.log('Starting updating fields');
    updateFields(values);
  }
  return (
    <>

      <FormikProvider value={formik}>
        <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Card sx={{ p: 3 }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography variant="subtitle1"> {translate('Company')} </Typography>
                      <LoadingButton
                        onClick={handleEdi}
                        variant="contained"
                        color="success"
                        loading={isSubmitting}
                      >
                        {translate('Save')}
                      </LoadingButton>

                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={8}>
                    <SoftBox p={1} pl={0}>
                      <SoftTypography variant="h6">Company Name</SoftTypography>
                    </SoftBox>
                    <SoftInput
                      fullWidth
                      placeholder={translate('name')}
                      {...getFieldProps('name')}
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SoftBox p={1} pl={0}>
                      <SoftTypography variant="h6">Organization Number</SoftTypography>
                    </SoftBox>
                    <SoftInput
                      fullWidth
                      placeholder={translate('Organization Number')}
                      {...getFieldProps('org_number')}
                      error={Boolean(touched.org_number && errors.org_number)}
                      helperText={touched.org_number && errors.org_number}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SoftBox p={1} pl={0}>
                      <SoftTypography variant="h6">Industry</SoftTypography>
                    </SoftBox>
                    <SoftInput
                      fullWidth
                      placeholder={translate('Industry')}
                      {...getFieldProps('industry')}
                      error={Boolean(touched.industry && errors.industry)}
                      helperText={touched.industry && errors.industry}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <SoftBox p={1} pl={0}>
                      <SoftTypography variant="h6">Employees</SoftTypography>
                    </SoftBox>
                    <SoftInput
                      fullWidth
                      placeholder={translate('Office Employees')}
                      {...getFieldProps('employees')}
                      error={Boolean(touched.employees && errors.employees)}
                      helperText={touched.employees && errors.employees}
                    />
                  </Grid>

                  <Grid item xs={12} sx={{ justifyContent: 'start' }}>
                    <Typography variant="subtitle1" justifyContent="start" sx={{ display: 'flex', justifyContent: 'start' }}> {translate('Remote Policy')} </Typography>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <SoftSelect
                      defaultValue={values.wfhPolicy}
                      name="wfhPolicy"
                      placeholder="Remote Policy"
                      value={{ value: values.wfhPolicy, label: values.wfhPolicy }}
                      onChange={(event, newValue) => {
                        setFieldValue('wfhPolicy', event.value);
                      }}
                      options={WFH_OPTIONS}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ display: 'flex', justifyContent: 'start' }}>{translate('Growth & Absence (%)')}</Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <SoftBox p={1} pl={0}>
                      <SoftTypography variant="h6">Growth</SoftTypography>
                    </SoftBox>
                    <SoftInput
                      fullWidth
                      placeholder={translate('Growth')}
                      {...getFieldProps('growthPercentage')}
                      error={Boolean(touched.growthPercentage && errors.growthPercentage)}
                      helperText={touched.growthPercentage && errors.growthPercentage}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <SoftBox p={1} pl={0}>
                      <SoftTypography variant="h6">Travel absence</SoftTypography>
                    </SoftBox>
                    <SoftInput
                      fullWidth
                      placeholder={translate('Travel')}
                      {...getFieldProps('travelPercentage')}
                      error={Boolean(touched.travelPercentage && errors.travelPercentage)}
                      helperText={touched.travelPercentage && errors.travelPercentage}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <SoftBox p={1} pl={0}>
                      <SoftTypography variant="h6">Sick absence</SoftTypography>
                    </SoftBox>
                    <SoftInput
                      fullWidth
                      placeholder={translate('Sick leave')}
                      {...getFieldProps('sickPercentage')}
                      error={Boolean(touched.sickPercentage && errors.sickPercentage)}
                      helperText={touched.sickPercentage && errors.sickPercentage}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <SoftBox p={1} pl={0}>
                      <SoftTypography variant="h6">Other absence</SoftTypography>
                    </SoftBox>
                    <SoftInput
                      fullWidth
                      placeholder={translate('Other Absence')}
                      {...getFieldProps('otherPercentage')}
                      error={Boolean(touched.otherPercentage && errors.otherPercentage)}
                      helperText={touched.otherPercentage && errors.otherPercentage}
                    />
                  </Grid>

                  {/* <Grid item xs={12}>
                    <Typography variant="subtitle1"> {translate('Fritext Marknad')} </Typography>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <TextField
                      fullWidth
                      value={values.markettext}
                      onChange={(event, newValue) => {
                        setFieldValue('markettext', newValue);
                        handleEdi();
                      }}
                      renderInput={(params) => <TextField {...params} label={translate('Fritext')} />}
                    />
                  </Grid> */}
                </Grid>
              </Card>
              <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
                <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }}>
                  {translate('updatesSaved')}
                </Alert>
              </Snackbar>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </>
  );
}
