import { Icon } from '@iconify/react';
import moonFill from '@iconify/icons-eva/moon-fill';
import sunFill from '@iconify/icons-eva/sun-fill';
import { IconButton } from '@mui/material';
// material
// hooks
import useSettings from '../../hooks/useSettings';

// ----------------------------------------------------------------------

export default function SettingMode({ isHome }) {
  const { themeMode, onChangeMode } = useSettings();
  return (
    <IconButton
      onClick={onChangeMode}
      name="themeMode"
      aria-label="Change mode"
      value={themeMode === 'dark' ? 'light' : 'dark'}
      sx={{
        ml: 0,
        ...(isHome && { color: 'primary.main' }),
        ...(themeMode === 'light' && { color: 'text.primary' })
      }}
    >
      <Icon icon={themeMode === 'dark' ? sunFill : moonFill} width={24} height={24} />
    </IconButton>
  );
}
