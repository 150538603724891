import { FilloutStandardEmbed } from "@fillout/react";
import {
  Box, Card, Step,
  StepLabel,
  Stepper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import pxToRem from "../../../assets/theme/functions/pxToRem.js";
import useAuth from "../../../hooks/useAuth.js";
import useIsMountedRef from "../../../hooks/useIsMountedRef.js";
// ----------------------------------------------------------------------

const ContentStyleSurvey = styled('div')(({ theme }) => ({
  margin: 'auto',
  marginLeft: pxToRem(274),
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12)
}));
export default function HomeSurvey() {
  const { user, login, logout, authcheck } = useAuth();
  const navigate = useNavigate();

  const isMountedRef = useIsMountedRef();
  const handleLoginAuth0 = async () => {
    try {
      await authcheck();
    } catch (error) {
      console.error(error);
    }
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      if (isMountedRef.current) {
        console.log(' handleClose();');
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };
  return (
    <>
{/*     <DashboardNavbar />
 */}    <ContentStyleSurvey>{/* 
      <Box sx={{ p: 2, pt: 1.5, position: "fixed", top: 5, right: 5 }}>
        <Button color="error" variant="filled" onClick={handleLogout}>
          Logout
        </Button>
      </Box> */}
      <Box sx={{ width: '100%', position: "absolut", bottom: 0, }}>
        <Stepper activeStep={2} alternativeLabel>
          {["Create User", "Connect Company", "Manager Quick Scan", "Scorecard", "Employee perspective", "Insights", "Premises programs"].map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
      <Card>
        <FilloutStandardEmbed
          filloutId="hy1YdhaK8Cus"
          inheritParameters
          dynamicResize
          parameters={{
            id: user.id,
            company_id: user.companyHubspot,
            survey_id: 1,
            company_name: user.companyName,
            email: user.email,
            journey_id: user.journey_id
          }}
        />
      </Card>
    </ContentStyleSurvey>
    </>
  );
}
