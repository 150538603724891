import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import book from '@iconify/icons-ic/book';
import bookOpenFill from '@iconify/icons-eva/book-open-fill';
import cameraFill from '@iconify/icons-eva/camera-fill';
// routes
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22
};

const menuConfig = [
  {
    title: 'home',
    icon: <Icon icon={homeFill} {...ICON_SIZE} />,
    path: '/'
  },
  {
    title: 'offer',
    path: PATH_PAGE.offer,
    icon: <Icon icon={cameraFill} {...ICON_SIZE} />
  },
  {
    title: 'Workplacer.ai',
    path: PATH_PAGE.plejs,
    icon: <Icon icon={cameraFill} {...ICON_SIZE} />
  },
  {
    title: 'Listings',
    path: PATH_PAGE.comingsoon,
    icon: <Icon icon={cameraFill} {...ICON_SIZE} />
  },
  {
    title: 'reference',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.references
  },
  {
    title: 'contact',
    icon: <Icon icon={bookOpenFill} {...ICON_SIZE} />,
    path: PATH_PAGE.contact
  }
];

export default menuConfig;
