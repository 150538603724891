import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
// slices
import chatReducer from './slices/chat';
import companyReducer from './slices/company';
import dealReducer from './slices/deal';
import placeReducer from './slices/place';
import userReducer from './slices/user';
import surveyReducer from './slices/survey';
import workplaceReducer from './slices/workplace';
import siteReducer from './slices/site';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  chat: chatReducer,
  place: placeReducer,
  user: userReducer,
  company: companyReducer,
  workplace: workplaceReducer,
  deal: dealReducer,
  survey: surveyReducer,
  site: siteReducer,
});

export { rootPersistConfig, rootReducer };
