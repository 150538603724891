import { Card } from '@mui/material';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LoadingScreen from '../components/LoadingScreen';
import SoftButton from '../components/SoftButton';
import SoftTypography from '../components/SoftTypography';
import DashboardLayout from '../examples/LayoutContainers/DashboardLayout';

// ----------------------------------------------------------------------

ProspectGuard.propTypes = {
  children: PropTypes.node
};

export default function ProspectGuard({ children }) {
  const navigate = useNavigate();
  const { contract } = useSelector((state) => state.site);
  if (!contract) {
    return (
      <LoadingScreen />
    );
  }
  if (!contract.prospects?.length) {
    return (
      <DashboardLayout>

        <Card sx={{ p: 3, justifyContent: 'center', alignContent: 'center', alignItems: 'center', height: '50vh' }}>
          <SoftTypography variant="h3" color="error" textGradient sx={{mb: 2}}>{`No prospects available for ${contract.name}`}</SoftTypography>
          <SoftButton variant="contained" color="primary" onClick={() => navigate('/')}>Return to Home</SoftButton>
        </Card>
      </DashboardLayout>
    );
  }

  return <>{children}</>;
}
