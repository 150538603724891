import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

import formquestions from "../../components/_dashboard/sampledata/formquestions.json";
import formquestionsmgmt from "../../components/_dashboard/sampledata/formquestionsmgmtnew.json";
import { filloutConfig } from "../../config";
import { workplacerMagicManager } from "../../utils/workplacerMgmtMagic";
import { updateJourney } from "./deal";
import { updateProspect } from "./site";
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLoadingQuestions: false,
  isLoadingResponses: false,
  isLoadingSurveys: false,
  isLoadingSurvey: false,
  isLoadingSubmissions: false,
  isLoadingMgmtSubmissions: false,
  isLoadingSurveyAnswers: false,
  response: "",
  survey: "",
  responses: [],
  respondents: [],
  surveys: [],
  answers: [],
  surveyAnswers: [],
  questions: [],
  submissions: "",
  mgmtMagic: "",
  mgmtSubmissions: "",
  fullyErgonomicDesksRatio: 0.8,
  smallRoomsRatio: 0.15,
  largeRoomsRatio: 0.05,
  totalSeatsRatio: 2,
  noSurveys: false,
  deepDive: null,
  midpoint: null,
  deepdives: []
};

const slice = createSlice({
  name: "workplace",
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadingQuestions(state) {
      state.isLoadingQuestions = true;
    },
    startLoadingResponses(state) {
      state.isLoadingResponses = true;
    },
    startLoadingSurveys(state) {
      state.isLoadingSurveys = true;
    },
    startLoadingSurvey(state) {
      state.isLoadingSurvey = true;
    },
    startLoadingSurveyAnswers(state) {
      state.isLoadingSurveyAnswers = true;
    },
    startLoadingSubmissions(state) {
      state.isLoadingSubmissions = true;
    },
    startLoadingMgmtSubmissions(state) {
      state.isLoadingMgmtSubmissions = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET POST INFINITE
    getQuestionsSuccess(state, action) {
      state.isLoadingQuestions = false;
      console.log("questions,", action.payload);
      const categoriesList = action.payload.map(
        (question) => question.category
      );
      const categories = [...new Set(categoriesList)];
      console.log("questions,", action.payload);
      state.questions = action.payload;
      state.categories = categories;
    },
    // GET POST INFINITE
    getResponsesSuccess(state, action) {
      state.isLoadingResponses = false;
      console.log(action.payload);
      state.responses = action.payload;
    },
    // GET POST INFINITE
    getSurveysSuccess(state, action) {
      state.isLoadingSurveys = false;
      console.log("surveys payload", action.payload);
      state.surveys = action.payload;
    },
    // GET POST INFINITE
    getEmptyMgmtSubmissionsSuccess(state, action) {
      console.log('NO SUBMISSIONS');
      state.isLoadingSurveys = false;
      state.noSurveys = true;
    },
    // GET POST INFINITE
    getSubmissionsSuccess(state, action) {
      state.isLoadingSubmissions = false;
      console.log('number of responses', action.payload);
      const payloadQuestions = action.payload
        .map((submission) => {
          const responses = submission.questions.map((question) => {
            return {
              ...question,
              submissionTime: submission.submissionTime,
              submissionId: submission.submissionId,
            };
          });
          return responses;
        })
        .flat();
      const demography = formquestions.demographyProfile;
      const workplace = formquestions.workplaceProfile;
      const activity = formquestions.activityProfile;
      const mobility = formquestions.mobilityProfile;
      const digital = formquestions.digitalProfile;
      const hybrid = formquestions.hybridProfile;
      const sound = formquestions.soundProfile;
      const demographyAnswers = demography.map((question) => {
        const answers = payloadQuestions.filter(
          (submission) => submission.id === question.id
        );
        const allOptions = answers.map((answer) => answer.value);
        const options = [...new Set(allOptions)];
        const optionsWithCount = options.map((option) => {
          const count = answers.filter((answer) => answer.value === option)
            .length;
          return { option, count };
        });
        return {
          ...question,
          answers,
          options: optionsWithCount,
          average:
            question.type === "Slider"
              ? answers.reduce((a, b) => a + b.value, 0) / answers.length
              : 0,
        };
      });
      const workplaceAnswers = workplace.map((question) => {
        const answers = payloadQuestions.filter(
          (submission) => submission.id === question.id
        );
        const allOptions = answers.map((answer) => answer.value);
        const options = [...new Set(allOptions)];
        const optionsWithCount = options.map((option) => {
          const count = answers.filter((answer) => answer.value === option)
            .length;
          return { option, count };
        });
        return {
          ...question,
          answers,
          options: optionsWithCount,
          average:
            question.type === "Slider"
              ? answers.reduce((a, b) => a + b.value, 0) / answers.length
              : 0,
        };
      });
      const activityAnswers = activity.map((question) => {
        const answers = payloadQuestions.filter(
          (submission) => submission.id === question.id
        );
        const allOptions = answers.map((answer) => answer.value);
        const options = [...new Set(allOptions)];
        const optionsWithCount = options.map((option) => {
          const count = answers.filter((answer) => answer.value === option)
            .length;
          return { option, count };
        });
        return {
          ...question,
          answers,
          options: optionsWithCount,
          average:
            question.type === "Slider"
              ? answers.reduce((a, b) => a + b.value, 0) / answers.length
              : 0,
        };
      });
      const mobilityAnswers = mobility.map((question) => {
        const answers = payloadQuestions.filter(
          (submission) => submission.id === question.id
        );
        const allOptions = answers.map((answer) => answer.value);
        const options = [...new Set(allOptions)];
        const optionsWithCount = options.map((option) => {
          const count = answers.filter((answer) => answer.value === option)
            .length;
          return { option, count };
        });
        return {
          ...question,
          answers,
          options: optionsWithCount,
          average:
            question.type === "Slider"
              ? answers.reduce((a, b) => a + b.value, 0) / answers.length
              : 0,
        };
      });
      const digitalAnswers = digital.map((question) => {
        const answers = payloadQuestions.filter(
          (submission) => submission.id === question.id
        );
        const allOptions = answers.map((answer) => answer.value);
        const options = [...new Set(allOptions)];
        const optionsWithCount = options.map((option) => {
          const count = answers.filter((answer) => answer.value === option)
            .length;
          return { option, count };
        });
        return {
          ...question,
          answers,
          options: optionsWithCount,
          average:
            question.type === "Slider"
              ? answers.reduce((a, b) => a + b.value, 0) / answers.length
              : 0,
        };
      });
      const hybridAnswers = hybrid.map((question) => {
        const answers = payloadQuestions.filter(
          (submission) => submission.id === question.id
        );
        const allOptions = answers.map((answer) => answer.value);
        const options = [...new Set(allOptions)];
        const optionsWithCount = options.map((option) => {
          const count = answers.filter((answer) => answer.value === option)
            .length;
          return { option, count };
        });
        return {
          ...question,
          answers,
          options: optionsWithCount,
          average:
            question.type === "Slider"
              ? answers.reduce((a, b) => a + b.value, 0) / answers.length
              : 0,
        };
      });
      const soundAnswers = sound.map((question) => {
        const answers = payloadQuestions.filter(
          (submission) => submission.id === question.id
        );
        const allOptions = answers.map((answer) => answer.value);
        const options = [...new Set(allOptions)];
        const optionsWithCount = options.map((option) => {
          const count = answers.filter((answer) => answer.value === option)
            .length;
          return { option, count };
        });
        return {
          ...question,
          answers,
          options: optionsWithCount,
          average:
            question.type === "Slider"
              ? answers.reduce((a, b) => a + b.value, 0) / answers.length
              : 0,
        };
      });
      const soundAdjusted = soundAnswers.map((question) => {
        const name = question.name;
        const series = {
          name,
          "Room": question.options.find((o) => o.option === "Room")?.count || 0,
          "Screened": question.options.find((o) => o.option === "Screened")?.count || 0,
          "Open": question.options.find((o) => o.option === "Open")?.count || 0
        }
        return series;
      })

      const workplaceAdjusted = workplaceAnswers.map((question) => {
        const name = question.name;
        const series = {
          name,
          office: question.options.find((o) => o.option === "Office")?.count || 0,
          officeOrOther: question.options.find((o) => o.option === "Office or other place")?.count || 0,
          other: question.options.find((o) => o.option === "Other place")?.count || 0,
          na: question.options.find((o) => o.option === "Do not perform")?.count || 0
        }
        return series;
      })
      const submissions = {
        demographyAnswers,
        workplaceAnswers,
        activityAnswers,
        mobilityAnswers,
        digitalAnswers,
        hybridAnswers,
        soundAnswers,
        soundAdjusted,
        workplaceAdjusted,
        responses: action.payload.length,
      };
      console.log("submissionzs,", submissions);
      state.submissions = submissions;
      state.respondents = action.payload.map((submission) => submission.submissionId);
    },

    // --------------- GET MGMT -------------------
    getMgmtSubmissionsSuccess(state, action) {
      state.isLoadingMgmtSubmissions = false;
      state.noSurveys = false;

      console.log('Success fetching. Now compiling... mgmt submissions', action.payload);
      const workplaces = action.payload.workplaces;
      const payloadQuestions = action.payload.questions.map((question) => {

        return {
          ...question,
          submissionTime: action.payload.submissionTime,
          submissionId: action.payload.submissionId,
        };
      })
        .flat();
      const workplace = formquestionsmgmt.workplaceProfile;
      const activity = formquestionsmgmt.activityProfile;
      const mobility = formquestionsmgmt.mobilityProfile;
      const digital = formquestionsmgmt.digitalProfile;
      const hybrid = formquestionsmgmt.hybridProfile;
      const sound = formquestionsmgmt.soundProfile;
      // console.log('read in check', payloadQuestions, formquestionsmgmt);
      const workplaceAnswers = workplace.map((question) => {
        const answers = payloadQuestions.filter(
          (submission) => submission.id === question.id
        );
        const allOptions = answers.map((answer) => answer.value);
        const options = [...new Set(allOptions)];
        const optionsWithCount = options.map((option) => {
          const count = answers.filter((answer) => answer.value === option)
            .length;
          return { option, count };
        });
        return {
          ...question,
          answers,
          options: optionsWithCount,
          average:
            question.type === "Slider"
              ? answers.reduce((a, b) => a + b.value, 0) / answers.length
              : 0,
        };
      });
      const activityAnswers = activity.map((question) => {
        const answers = payloadQuestions.filter(
          (submission) => submission.id === question.id
        );
        const allOptions = answers.map((answer) => answer.value);
        const options = [...new Set(allOptions)];
        const optionsWithCount = options.map((option) => {
          const count = answers.filter((answer) => answer.value === option)
            .length;
          return { option, count };
        });
        return {
          ...question,
          answers,
          options: optionsWithCount,
          average:
            question.type === "Slider"
              ? answers.reduce((a, b) => a + b.value, 0) / answers.length
              : 0,
        };
      });
      const mobilityAnswers = mobility.map((question) => {
        const answers = payloadQuestions.filter(
          (submission) => submission.id === question.id
        );
        const allOptions = answers.map((answer) => answer.value);
        const options = [...new Set(allOptions)];
        const optionsWithCount = options.map((option) => {
          const count = answers.filter((answer) => answer.value === option)
            .length;
          return { option, count };
        });
        return {
          ...question,
          answers,
          options: optionsWithCount,
          average:
            question.type === "Slider"
              ? answers.reduce((a, b) => a + b.value, 0) / answers.length
              : 0,
        };
      });
      const digitalAnswers = digital.map((question) => {
        const answers = payloadQuestions.filter(
          (submission) => submission.id === question.id
        );
        const allOptions = answers.map((answer) => answer.value);
        const options = [...new Set(allOptions)];
        const optionsWithCount = options.map((option) => {
          const count = answers.filter((answer) => answer.value === option)
            .length;
          return { option, count };
        });
        return {
          ...question,
          answers,
          options: optionsWithCount,
          average:
            question.type === "Slider"
              ? answers.reduce((a, b) => a + b.value, 0) / answers.length
              : 0,
        };
      });
      const hybridAnswers = hybrid.map((question) => {
        const answers = payloadQuestions.filter(
          (submission) => submission.id === question.id
        );
        const allOptions = answers.map((answer) => answer.value);
        const options = [...new Set(allOptions)];
        const optionsWithCount = options.map((option) => {
          const count = answers.filter((answer) => answer.value === option)
            .length;
          return { option, count };
        });
        return {
          ...question,
          answers,
          options: optionsWithCount,
          average:
            question.type === "Slider"
              ? answers.reduce((a, b) => a + b.value, 0) / answers.length
              : 0,
        };
      });
      const soundAnswers = sound.map((question) => {
        const answers = payloadQuestions.filter(
          (submission) => submission.id === question.id
        );
        const allOptions = answers.map((answer) => answer.value);
        const options = [...new Set(allOptions)];
        const optionsWithCount = options.map((option) => {
          const count = answers.filter((answer) => answer.value === option)
            .length;
          return { option, count };
        });
        return {
          ...question,
          answers,
          options: optionsWithCount,
          average:
            question.type === "Slider"
              ? answers.reduce((a, b) => a + b.value / 100, 0) / answers.length
              : 0,
        };
      });
      const soundAdjusted = soundAnswers.map((question) => {
        // console.log('question', question);
        const name = question.name;
        const series = {
          name,
          "Room": question.options.find((o) => o.option === "Room")?.count || 0,
          "Screened": question.options.find((o) => o.option === "Screened")?.count || 0,
          "Open": question.options.find((o) => o.option === "Open")?.count || 0
        }
        return series;
      })

      const workplaceAdjusted = workplaceAnswers.map((question) => {
        const name = question.name;
        const series = {
          name,
          activity_id: question.activity_id,
          office: question.average || 0,
        }
        return series;
      })
     // console.log('old check', payloadQuestions);

      const employeesId = "g4PUA5TDGp8uVWNspapwih";
      const rentId = "bJxmWdZm719AJ7G4TBdUcE";
      const sqmId = "pWUj9xFh2XEtSCZutMwnWu";
      const addressId = "dMT58eDSMEkXeMc1gETWQ2"
      const occupancyId = "vnnzmA2cqRKs96LkP7V9yF"
      const occupancyPeakId = "tXPuZqfBJ2Ub5sH9oLo6cH";
      const occupancyLowId = "gkXHDNsuPkJDkkN8pqjhXT";


      const employees = payloadQuestions.find(
        (submission) => submission.id === employeesId
      ).value;
      const rent = payloadQuestions.find(
        (submission) => submission.id === rentId
      ).value;
      const sqm = payloadQuestions.find(
        (submission) => submission.id === sqmId
      ).value;
      const address = payloadQuestions.find(
        (submission) => submission.id === addressId
      ).value;
      const occupancy = payloadQuestions.find(
        (submission) => submission.id === occupancyId
      ).value;
      const occupancyPeak = payloadQuestions.find(
        (submission) => submission.id === occupancyPeakId
      ).value;
      const occupancyLow = payloadQuestions.find(
        (submission) => submission.id === occupancyLowId
      ).value;

      /* 
            const growthId = "8fMPuptYkn19n7ywB7Zd9c";
      
            const growth = payloadQuestions.find(
              (submission) => submission.id === growthId
            ).value; */

     // console.log('check wq');

      const openWorkAreaId = "9QJp4PSxRqUeZyqXM86qrq";
      const socialAreaId = "jvnbhWJZQBDCpkbWr951Ty";
      const otherAreaId = "7iStpowUCxtBsWV8ZhuyF7";

      const openWorkArea = payloadQuestions.find(
        (submission) => submission.id === openWorkAreaId
      )?.value;
      const socialArea = payloadQuestions.find(
        (submission) => submission.id === socialAreaId
      )?.value;
      const otherArea = payloadQuestions.find(
        (submission) => submission.id === otherAreaId
      )?.value;

      const firstWordId = "mxSPigV6awWHudy1hG7Tw1";
      const secondWordId = "brsv8NM3RSrw9SHXd9ZD9V";
      const thirdWordId = "fDxDEkQXXmGgV1pQsnnAa8";


      const firstWord = payloadQuestions.find(
        (submission) => submission.id === firstWordId
      )?.value;
      const secondWord = payloadQuestions.find(
        (submission) => submission.id === secondWordId
      )?.value;
      const thirdWord = payloadQuestions.find(
        (submission) => submission.id === thirdWordId
      )?.value;
      const remotePolicyId = "gmZsEBqtY9iCyjMbCWV4Dr";
      const officeDaysId = "5v5Sd8w4b1wu4vptmJFLiP";
      const fullyRemoteAgreementId = "g5HWYwY55YarYjUKb97n9o";
      const qtFullyRemoteId = "6eLZNssFU5ooz7A2sjtMRs";

      const remotePolicy = payloadQuestions.find(
        (submission) => submission.id === remotePolicyId
      )?.value;
      const officeDays = payloadQuestions.find(
        (submission) => submission.id === officeDaysId
      )?.value;/* 
      const spaceFormula = payloadQuestions.find(
        (submission) => submission.id === spaceFormulaId
      ).value;
      const percentageFormula = payloadQuestions.find(
        (submission) => submission.id === percentageFormulaId
      ).value; */

      const fullyRemoteAgreement = payloadQuestions.find(
        (submission) => submission.id === fullyRemoteAgreementId
      )?.value;

      const qtFullyRemote = payloadQuestions.find(
        (submission) => submission.id === qtFullyRemoteId
      )?.value;
      const submissions = {
        workplaceAnswers,
        activityAnswers,
        mobilityAnswers,
        digitalAnswers,
        hybridAnswers,
        soundAnswers,

        soundAdjusted,
        workplaceAdjusted,
        employees,
        rent,
        sqm,
        address,
        remotePolicy,
        officeDays,
        fullyRemoteAgreement,
        qtFullyRemote,
        occupancy,
        firstWord,
        secondWord,
        thirdWord,
        occupancyPeak,
        occupancyLow,
        openWorkArea,
        socialArea,
        otherArea,
        fullyErgonomicDesksRatio: state.fullyErgonomicDesksRatio,
        smallRoomsRatio: state.smallRoomsRatio,
        largeRoomsRatio: state.largeRoomsRatio,
        totalSeatsRatio: state.totalSeatsRatio
      };

      console.log('dispatch mgmt submissions completed, now running magic', submissions);
      const magic = workplacerMagicManager(submissions, workplaces);
      // console.log("mgmt finished submissionzs,", submissions);
      console.log('dispatch magic completed', magic);
      state.mgmtSubmissions = submissions;
      state.mgmtMagic = {
        ...magic, submissions,
        fullyErgonomicDesksRatio: state.fullyErgonomicDesksRatio,
        smallRoomsRatio: state.smallRoomsRatio,
        largeRoomsRatio: state.largeRoomsRatio,
        totalSeatsRatio: state.totalSeatsRatio
      };
    },
    // GET POST INFINITE
    getSurveySuccess(state, action) {
      state.isLoadingSurvey = false;
      console.log("survey payload", action.payload);
      state.survey = action.payload;
    },

    // GET POST INFINITE
    getDeepDiveSuccess(state, action) {
      state.isLoading = false;
      console.log("deep dive payload", action.payload);
      state.deepDive = action.payload;
    },
    getDeepDivesSuccess(state, action) {
      state.isLoading = false;
      console.log("deep dives payload", action.payload);
      state.deepdives = action.payload;
    },
    getMidpointSuccess(state, action) {
      state.isLoading = false;
      console.log("midpoint payload", action.payload);
      state.midpoint = action.payload;
    },
    // GET POST INFINITE
    getSurveyAnswersSuccess(state, action) {
      state.isLoadingSurveyAnswers = false;
      console.log("survey answers payload", action.payload);
      state.answers = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
// Actions
// ----------------------------------------------------------------------

export function getQuestions() {
  console.log("questions loading");
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingQuestions());
    try {
      const response = await axios.get(
        "https://plejsapp-api.herokuapp.com/api/v1/questions/index"
      );
      console.log("questions,", response);
      dispatch(slice.actions.getQuestionsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSurveys(company_id) {
  console.log("aatvv");
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingSurveys());
    try {
      const response = await axios.get(
        "https://plejsapp-api.herokuapp.com/api/v1/surveys/index",
        {
          params: { company_id },
        }
      );
      console.log("avt,", response);
      dispatch(slice.actions.getSurveysSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSubmissions(company_id) {
  console.log("submissions");
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingSubmissions());
    try {
      const token = filloutConfig;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get(
        "https://api.fillout.com/v1/api/forms/7FoEN736bsus/submissions",
        config
      );
      console.log("submissions,", response);
      const filtered = response.data.responses.filter(
        (submission) => submission.urlParameters[1].value === `${company_id}`
      );
      console.log("filtered,", filtered);
      dispatch(slice.actions.getSubmissionsSuccess(filtered));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getMgmtSubmissions(company_id) {
  console.log("submissions");
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingMgmtSubmissions());
    try {
      const token = filloutConfig
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get(
        "https://api.fillout.com/v1/api/forms/hy1YdhaK8Cus/submissions",
        config
      );
      console.log("submissions mgmt,", response);
      const filtered = response.data.responses.filter(
        (submission) => submission.urlParameters[1].value === `${company_id}`
      );
      const resp = filtered[filtered.length - 1];
      console.log("filtered,", filtered);
      dispatch(slice.actions.getMgmtSubmissionsSuccess(resp));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getJourneyMgmtSubmissions(company_id, journey_id, workplaces, hasSubmission) {
  console.log("Starting to fetch mgmt submissions", company_id, journey_id, workplaces);
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingMgmtSubmissions());
    try {
      const token = filloutConfig;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get(
        "https://api.fillout.com/v1/api/forms/hy1YdhaK8Cus/submissions",
        config
      );
      //console.log("submissions mgmt,", response.data.responses.map((d) => d.urlParameters));
      const filtered = response.data.responses.filter(
        (submission) => submission.urlParameters[1].value === `${company_id}` && submission.urlParameters[5].value === `${journey_id}`
      );
      if (filtered.length === 0) {
        dispatch(slice.actions.getEmptyMgmtSubmissionsSuccess());
      } else {

        const resp = filtered[filtered.length - 1];
        console.log('journey specific submissions', resp);
        if (!hasSubmission) {
          dispatch(updateJourney(journey_id, { submission_id: resp.submissionId }))
        }
        const respi = { ...resp, workplaces };
        dispatch(slice.actions.getMgmtSubmissionsSuccess(respi));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
export function getProspectMgmtSubmissions(company_id, prospect_id, workplaces, hasSubmission) {
  console.log("Starting to fetch mgmt submissions", company_id, prospect_id, workplaces);
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingMgmtSubmissions());
    try {
      const token = filloutConfig;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      const response = await axios.get(
        "https://api.fillout.com/v1/api/forms/hy1YdhaK8Cus/submissions",
        config
      );
      //console.log("submissions mgmt,", response.data.responses.map((d) => d.urlParameters));
      const urlParams = response.data.responses.map((d) => ({id: d.submissionId, urlParams: d.urlParameters}))
      console.log('urlParams', urlParams, prospect_id);
      const filtered = response.data.responses.filter(
        (submission) => submission.urlParameters[1].value === `${company_id}` && submission.urlParameters.find((up) => up.name === 'prospect_id').value === `${prospect_id}`
      );
      if (filtered.length === 0) {
        dispatch(slice.actions.getEmptyMgmtSubmissionsSuccess());
      } else {

        const resp = filtered[filtered.length - 1];
        console.log('journey specific submissions', resp);
        if (!hasSubmission) {
          //dispatch(updateProspect(prospect_id, { submission_id: resp.submissionId }))
        }
        const respi = { ...resp, workplaces };
        dispatch(slice.actions.getMgmtSubmissionsSuccess(respi));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getSurvey(id) {
  console.log("aatvv");
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingSurvey());
    try {
      const response = await axios.get(
        `https://plejsapp-api.herokuapp.com/api/v1/surveys/survey/${id}`
      );
      console.log("avt,", response);
      dispatch(slice.actions.getSurveySuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getDeepDive(id) {
  console.log("aatvv");
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `https://plejsapp-api.herokuapp.com/api/v1/deepdives/deepdive/${id}`
      );
      console.log("avt,", response);
      dispatch(slice.actions.getDeepDiveSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getJourneyDeepDives(id) {
  console.log("aatvv");
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `https://plejsapp-api.herokuapp.com/api/v1/deepdives/journeyindex/${id}`
      );
      console.log("avt,", response);
      dispatch(slice.actions.getDeepDivesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getMidpoint(id) {
  console.log("aatvv");
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `https://plejsapp-api.herokuapp.com/api/v1/midpoints/midpoint/${id}`
      );
      console.log("avt,", response);
      dispatch(slice.actions.getMidpointSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getSurveyAnswers(id) {
  console.log("aatvv");
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingSurveyAnswers());
    try {
      const response = await axios.get(
        `https://plejsapp-api.herokuapp.com/api/v1/answers/surveyindex/${id}`
      );
      console.log("avt,", response);
      dispatch(slice.actions.getSurveyAnswersSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getResponses() {
  console.log("aatvv");
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingResponses());
    try {
      const response = await axios.get(
        "https://plejsapp-api.herokuapp.com/api/v1/responses/index"
      );
      console.log("avt,", response);
      dispatch(slice.actions.getResponsesSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getWorkplaceprofiles() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `https://plejsapp-api.herokuapp.com/api/v1/workplaceprofiles/index`
      );
      dispatch(slice.actions.getWorkplaceprofilesSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------

export function getWorkplace(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get(
        `https://plejsapp-api.herokuapp.com/api/v1/workplaces/workplace/${id}`,
        {
          params: { id },
        }
      );
      dispatch(slice.actions.getWorkplaceSuccess(response.data));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
