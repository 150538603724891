/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "../../../../../../components/SoftBox";
import SoftTypography from "../../../../../../components/SoftTypography";
import SoftInput from "../../../../../../components/SoftInput";
import { updateCompany } from "../../../../../../redux/slices/company";
import { dispatch } from "../../../../../../redux/store";
import { useSelector } from "react-redux";
import SoftSnackbar from "../../../../../../components/SoftSnackbar";
import { alpha, styled } from "@mui/material";

function PlatformSettings() {
  const { company } = useSelector((state) => state.company);
  const [followsMe, setFollowsMe] = useState(true);
  const [answersPost, setAnswersPost] = useState(false);
  const [mentionsMe, setMentionsMe] = useState(true);
  const [newLaunches, setNewLaunches] = useState(false);
  const [productUpdate, setProductUpdate] = useState(true);
  const [newsletter, setNewsletter] = useState(true);
  const [primaryColor, setPrimaryColor] = useState(company?.primary_color || "#000000");
  const [secondaryColor, setSecondaryColor] = useState(company?.secondary_color || "#000000");
  const [open, setOpen] = useState(false);
  console.log('primaryColor', primaryColor);
  console.log('secondaryColor', secondaryColor);
  useEffect(() => {
    if (primaryColor.length !== 7 || secondaryColor.length !== 7) return;
    if (primaryColor[0] !== '#' || secondaryColor[0] !== '#') return;
    if (primaryColor !== company?.primary_color || secondaryColor !== company?.secondary_color) {
      dispatch(updateCompany(company?.id, { primary_color: primaryColor, secondary_color: secondaryColor }));
      setOpen(true);
    }
  }, [primaryColor, secondaryColor]);
  const PinkSwitch = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: company?.primary_color || '#000000',
      '&:hover': {
        backgroundColor: alpha(company?.primary_color || theme.palette.primary.main, theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: company?.primary_color || theme.palette.primary.main,
    },
  }));
  const handleStrategy = () => {
    dispatch(updateCompany(company?.id, { localStrategy: !company?.localStrategy }));
  }
  const handleApproval = () => {
    dispatch(updateCompany(company?.id, { localApproval: !company?.localApproval }));
  }
  return (


    <Card>
      <SoftSnackbar
        open={open}
        onClose={() => setOpen(false)}
        icon="notifications"
        title="Company updated"
        content="Successfully updated company colors"
        dateTime="now"
        duration={3000}
      />

      <SoftBox pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          platform settings
        </SoftTypography>
      </SoftBox>
      <SoftBox pt={1.5} pb={2} px={2} lineHeight={1.25}>
        <SoftTypography variant="caption" fontWeight="bold" color="text" textTransform="uppercase">
          approval
        </SoftTypography>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <PinkSwitch checked={company?.localStrategy} onChange={handleStrategy} />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Global strategy can be edited locally
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <PinkSwitch checked={company?.localApproval} onChange={handleApproval} />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Local users can approve prospects
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox mt={3}>
          <SoftTypography
            variant="caption"
            fontWeight="bold"
            color="text"
            textTransform="uppercase"
          >
            application
          </SoftTypography>
        </SoftBox>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <SoftInput value={primaryColor} sx={{ backgroundColor: primaryColor || '#ffffff', color: primaryColor || '#000000' }} onChange={(e) => setPrimaryColor(e.target.value)} />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Company primary color:
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        <SoftBox display="flex" py={1} mb={0.25}>
          <SoftBox mt={0.25}>
            <SoftInput
              value={secondaryColor}
              sx={{ backgroundColor: secondaryColor, color: secondaryColor }}
              onChange={(e) => setSecondaryColor(e.target.value)}
            />
          </SoftBox>
          <SoftBox width="80%" ml={2}>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Company secondary color:
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

export default PlatformSettings;
