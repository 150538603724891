import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import 'mapbox-gl/dist/mapbox-gl.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/lib/integration/react';
import 'simplebar/src/simplebar.css';
import App from './App';
import LoadingScreen from './components/LoadingScreen';
import { AuthProvider } from './contexts/Auth0Context';
import { SettingsProvider } from './contexts/SettingsContext';
import "./globals.css";
import "./index.css";
import './locales/i18n';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { SoftUIControllerProvider } from './softcontext';

const root = ReactDOM.createRoot(document.getElementById('root'));

// ----------------------------------------------------------------------
ReactGA.initialize('UA-215118789-1');
ReactGA.pageview(window.location.pathname + window.location.search);

root.render(
  <HelmetProvider>
    <ReduxProvider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <SettingsProvider>
            <BrowserRouter>
              <AuthProvider>
                <SoftUIControllerProvider>
                  <App />
                </SoftUIControllerProvider>
              </AuthProvider>
            </BrowserRouter>
          </SettingsProvider>
        </LocalizationProvider>
      </PersistGate>
    </ReduxProvider>
  </HelmetProvider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
