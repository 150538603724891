import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { CSVLink } from "react-csv";
import bulbFill from '@iconify/icons-eva/bulb-fill';
import Geocode from 'react-geocode';
import { Link as RouterLink } from 'react-router-dom';

import sample from '../sampledata/samplerestid.json'
// material
import { useTheme, styled, alpha } from '@mui/material/styles';
import {
  Box,
  Card,
  Grid,
  Link,
  Container,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  Button,
  TextField,
  useMediaQuery,
  Stack
} from '@mui/material';

// routes
import useLocales from '../../../hooks/useLocales';
import CsvUploader from '../../../utils/CsvUploader3.tsx';
import { AddressForm, InsightCard } from '.';
import Label from '../../Label';
import { googleConfig, geoConfig } from '../../../config';

Geocode.setApiKey(geoConfig);

// ----------------------------------------------------------------------

const RootStyleFour = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingBottom: theme.spacing(0),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(10),
    textAlign: 'left'
  }
}));

// ----------------------------------------------------------------------
const HEADERS = [
  { label: "Id", key: "id" },
  { label: "Address", key: "address" },
  { label: "Zipcode", key: "zipcode" },
  { label: "City", key: "city" },
  { label: "Biking", key: "is_bike" },
  { label: "Kids", key: "kids" }
];

export default function Distance({placeis}) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { translate } = useLocales();
  const [data, setData] = useState([]);
  const [output, setOutput] = useState([]);
  const [originsString, setOriginsString] = useState('');
  const [originsArray, setOriginsArray] = useState([]);
  const [place, setPlace] = useState(placeis);
  const [averageTime, setAverageTime] = useState(0.0);
  const [averageDistance, setAverageDistance] = useState(0.0);
  const [underThirty, setUnderThirthy] = useState(0);
  const [open, setOpen] = useState(false);
  const isLight = theme.palette.mode === 'light';

  useEffect(() => {
    if (place || !placeis) return;
    setPlace(placeis);
  }, [placeis]);

  useEffect(() => {
    if (!originsString || !place) return;
    console.log('originss', originsString);
    const { name, zip, city, city_name, country, country_name } = place;
    const placeStr = `${name} ${zip} ${city || city_name} ${country || country_name}`;
    console.log('placestr', placeStr, place);
    const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${encodeURIComponent(originsString)}&destinations=${encodeURIComponent(placeStr)}&departure_time=now&key=${googleConfig}`;
    getGplaces(url, originsArray);
  }, [originsString, place]);

  useEffect(() => {
    if (!output) return;
    console.log('outout', output);
    const time = output.map((o) => o.duration.value).reduce((a, v) => (a += v), 0) / 60;
    const averageT = Math.round(time / output.length, 0);
    const distance = output.map((o) => o.distance.value).reduce((a, v) => (a += v), 0) / 1000;
    const averageD = Math.round(distance / output.length, 1);
    const subthirthy = output.filter((o) => o.duration.value < 1800).length;
    setAverageTime(averageT);
    setAverageDistance(averageD);
    setUnderThirthy(subthirthy);
  }, [output]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleReset = () => {
    setData([]);
    setOutput([]);
  };
  const handleUpload = (d) => {
    setData(d);
    console.log(d);
  };
  async function getGplaces(url, arr) {
    const urlInt = 'https://plejsapp-api.herokuapp.com/api/v1/missions/gplaces'
    const response = await axios.get(urlInt, {
      params: { url }
    });
    console.log('response', response);
    const results = response.data.rows.map((r) => r.elements[0]) || [];
    const originsWithdata = arr.map((o, idx) => {
      const result = results[idx];
      return { ...o, ...result };
    });
    console.log('originswithdata', originsWithdata);
    setOutput(originsWithdata);
  }

  const handlePlace = (pl) => {
    setPlace(pl);
  };
  const handleOkSample = () => {
    console.log(sample);
    let origins = '';
    let originArray = [];
    for (let i = 1; i < sample.length; i += 1) {
      const id = sample[i][0];
      const address = sample[i][1];
      const zipcode = sample[i][2];
      const city = sample[i][3];
      const isBike = sample[i][4];
      const kids = sample[i][5];
      const obj = {
        id,
        address,
        zipcode,
        city,
        isBike,
        kids
      };
      const originText = `${address}%20${zipcode}%20${city}`;
      console.log('origintext', originText);
      originArray.push(obj);
      origins += originText;
      if (i !== sample.length - 1) {
        origins += '%7C';
      }
    }
    setOpen(false);
    setOriginsString(origins);
    setOriginsArray(originArray);
  };
  const handleOk = () => {
    let origins = '';
    let originArray = [];
    for (let i = 1; i < data.length; i += 1) {
      const id = data[i][0];
      const address = data[i][1];
      const zipcode = data[i][2];
      const city = data[i][3];
      const isBike = data[i][4];
      const kids = data[i][5];
      const obj = {
        id,
        address,
        zipcode,
        city,
        isBike,
        kids
      };
      const originText = `${address}%20${zipcode}%20${city}`;
      originArray.push(obj);
      origins += originText;
      if (i !== data.length - 1) {
        origins += '|';
      }
    }
    setOpen(false);
    setOriginsString(origins);
    setOriginsArray(originArray);
  };
  return (
    <RootStyleFour>
      <Container maxWidth="lg">
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>{translate('Upload origins')}</DialogTitle>
          <DialogContent>
            <DialogContentText>{translate('Make sure to use template formatting.')}</DialogContentText>
            <CsvUploader onUpload={handleUpload} />
            <Button onClick={handleOkSample}>{translate('Use Sample data')}</Button>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>{translate('Cancel')}</Button>
            <Button onClick={handleOk}>{translate('Add')}</Button>
          </DialogActions>
        </Dialog>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {!originsArray || originsArray.length < 1 ? (
              <>
                <Typography variant="h4" color={theme.palette.primary.main}>
                  1. {translate('Add origins')}
                </Typography>
                <br />
                <Button variant="contained" onClick={handleOpen}>{translate('Upload origins')}</Button>
              </>
            ) : null}
            {originsArray && originsArray.length > 0 && place && (
              <Grid container spacing={3} sx={{ alignItems: 'center' }}>
                <Grid item xs={12} md={4}>
                  <InsightCard
                    heading={translate('Average travel time')}
                    unit={'min'}
                    value={averageTime}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InsightCard
                    heading={translate('Average travel distance')}
                    unit={'km'}
                    value={averageDistance}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InsightCard
                    heading={translate('Employees within 30 min')}
                    unit={`(${Math.round(100 * underThirty / output.length, 0)}%)`}
                    value={underThirty}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
          {originsArray && originsArray.length > 0 && (
            <AddressForm onPlace={handlePlace} originsArray={originsArray} place={place} output={output} />
          )}
          </Grid>
          { output && output.length > 1 && (
            <Grid item xs={12}>
              <Typography variant="h4" color={theme.palette.primary.main}>
                {translate('Download Output')}
              </Typography>
              <br />
              <CSVLink data={output} headers={HEADERS}>
                {translate('Download csv now')}
              </CSVLink>
            </Grid>
          )}
        </Grid>
      </Container>
    </RootStyleFour>
  );
}
