/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import { useDispatch, useSelector } from "../../redux/store";

// Soft UI Dashboard PRO React components
import SoftBox from "../../components/SoftBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
  collapseArrow,
} from "./styles/sidenavCollapse";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "../../softcontext";
import { Chip, Switch } from "@mui/material";
import SoftTypography from "../../components/SoftTypography";
import SoftBadge from "../../components/SoftBadge";

function SidenavMain({ completed, icon, name, children, active, isSwitch, isFuture, noCollapse, open, ...rest }) {
  const [controller] = useSoftUIController();
  const { miniSidenav, transparentSidenav, sidenavColor } = controller;
  const { journey } = useSelector((state) => state.site);
  const status = () => {
    if (name === "Enter company details") {
      if (journey.company?.employees & journey.space?.sqm) {
        return 'success'
      } else if (journey.company?.employees || journey.space?.sqm) {
        return 'warning'
      } else {
        return 'error'
      }
    } else if (name === "Quick Scan") {
      if (journey?.hasCompletedMgmtSurvey) {
        return 'success'
      } else {
        return 'error'
      }
    } else if (name === "Employee Deep Dive") {
      if (journey?.hasClosedSurvey) {
        return 'success'

      } else if (journey?.hasSentSurvey) {
        return 'warning'
      } else {
        return 'error'
      }
    } else if (name === "Workplace Strategy") {
      if (journey?.has_strategy) {
        return 'success'

      }
    } else if (name === "Workplacer Bricks") {
      if (journey?.has_strategy) {
        return 'warning'

      }
    } else if (name === "Premises Program" || name === "Search Ad") {
      if (journey?.has_spacebricks) {
        return 'success'

      }
    } else if (name === "Strategy Tool") {
      if (journey?.has_strategy || (journey?.has_goals && journey?.has_search_area && journey?.has_work_model && journey?.has_base)) {
        return 'success'

      } else if (journey?.has_goals || journey?.has_search_area || journey?.has_work_model || journey?.has_base) {
        return 'warning'
      } else {
        return 'disabled'
      }
    } else return null;
  }
  const isReady = () => {
    if (name === "Workplace Strategy") {
      if (journey?.has_strategy) {
        return true
      } else {
        return false
      }
    } else if (name === "Premises Program") {
      if (journey?.has_spacebricks) {
        return true

      } else {
        return false
      }
    } else return false;
  }
  const reqReady = () => {
    if (name === "Workplace Strategy" || name === "Premises Program") {
      return true
    } else return false;
  }
  return (
    <>
      <ListItem component="li">
        <SoftBox justifyContent="space-between" alignItems="center" display="flex" width="100%" {...rest}>
          <SoftBox {...rest} sx={(theme) => collapseItem(theme, { active, transparentSidenav, disabled: isFuture })}>
            <ListItemIcon
              sx={(theme) => collapseIconBox(theme, { 
                active, 
                transparentSidenav, 
                sidenavColor, 
                completed, 
                status: status(), 
                name, 
                isReady: isReady(), 
                reqReady: reqReady(),
                disabled: isFuture 
              })}
            >
              {typeof icon === "string" ? (
                <Icon 
                  disabled={status() === 'disabled' || isFuture} 
                  sx={(theme) => collapseIcon(theme, { active, isFuture, disabled: isFuture })}
                >
                  {icon}
                </Icon>
              ) : (
                icon
              )}
            </ListItemIcon>

            <ListItemText
              primary={name}
              component={<SoftTypography />}
              sx={(theme) => collapseText(theme, { 
                miniSidenav, 
                transparentSidenav, 
                active, 
                isFuture, 
                isReady: isReady(), 
                reqReady: reqReady(),
                disabled: isFuture 
              })}
            />
          </SoftBox>
          <SoftBox sx={{ position: 'absolute', right: 0 }}>
            {isFuture && !miniSidenav && (
              <SoftBadge 
                badgeContent="Planned" 
                circular 
                color="secondary" 
                variant="gradient" 
                size="xs" 
                container 
              />
            )}
          </SoftBox>
        </SoftBox>
      </ListItem>
    </>
  );
}

// Setting default values for the props of SidenavCollapse
SidenavMain.defaultProps = {
  color: "info",
  active: false,
  noCollapse: false,
  children: false,
  open: false,
};

// Typechecking props for the SidenavCollapse
SidenavMain.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  children: PropTypes.node,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
};

export default SidenavMain;
