import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
  useMediaQuery
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { CSVLink } from "react-csv";
import Geocode from 'react-geocode';
import { AddressForm, InsightCard } from '.';
import { geoConfig, googleConfig } from '../../../config';
import useLocales from '../../../hooks/useLocales';
import CsvUploader from '../../../utils/CsvUploader3.tsx';
import Stepper from '../../StepperDistance';
import sample from '../sampledata/samplerestid.json';
import { UploadFileOutlined } from '@mui/icons-material';

Geocode.setApiKey(geoConfig);

// ----------------------------------------------------------------------

const RootStyleFour = styled('div')(({ theme }) => ({
  textAlign: 'center',
  paddingBottom: theme.spacing(0),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left'
  }
}));

// ----------------------------------------------------------------------
const HEADERS = [
  { label: "Id", key: "id" },
  { label: "Address", key: "address" },
  { label: "Zipcode", key: "zipcode" },
  { label: "City", key: "city" },
  { label: "Biking", key: "is_bike" },
  { label: "Kids", key: "kids" }
];

export default function Distance({ journey, placeis, addresses }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const { translate } = useLocales();
  const [data, setData] = useState([]);
  const [output, setOutput] = useState([]);
  const [originsString, setOriginsString] = useState('');
  const [originsArray, setOriginsArray] = useState([]);
  const [place, setPlace] = useState(placeis);
  const [step, setStep] = useState(1);
  const [percent, setPercent] = useState(1);
  const [averageTime, setAverageTime] = useState(0.0);
  const [averageDistance, setAverageDistance] = useState(0.0);
  const [underThirty, setUnderThirthy] = useState(0);
  const [open, setOpen] = useState(false);
  const isLight = theme.palette.mode === 'light';


  useEffect(() => {
    if (!originsString || !place) return;
    console.log('originss', originsString);
    const { name, zip, city, city_name, country, country_name } = place;
    const placeStr = `${name} ${zip} ${city || city_name} ${country || country_name}`;
    console.log('placestr', placeStr, place);
    const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${encodeURIComponent(originsString)}&destinations=${encodeURIComponent(placeStr)}&departure_time=now&key=${googleConfig}`;
    getGplaces(url, originsArray);
    setStep(3);
  }, [originsString, place]);

  useEffect(() => {
    if (output) {
      console.log('outout', output);
      const time = output.filter((o) => o.status === "OK").map((o) => o.duration?.value).reduce((a, v) => (a += v), 0) / 60;
      const averageT = Math.round(time / output.length, 0);
      const distance = output.filter((o) => o.status === "OK").map((o) => o.distance?.value).reduce((a, v) => (a += v), 0) / 1000;
      const averageD = Math.round(distance / output.length, 1);
      const subthirthy = output.filter((o) => o.status === "OK").filter((o) => o.duration?.value < 1800).length;
      setAverageTime(averageT);
      setAverageDistance(averageD);
      setUnderThirthy(subthirthy);
      setPercent(Math.round(100 * subthirthy / output.length, 0))
      setStep(3);
    };
    if (addresses) {

      console.log('addresses', addresses);
      const time = addresses.map((o) => o.duration).reduce((a, v) => (a += v), 0) / 60;
      const averageT = Math.round(time / addresses.length, 0);
      const distance = addresses.map((o) => o.distance).reduce((a, v) => (a += v), 0) / 1000;
      const averageD = Math.round(distance / addresses.length, 1);
      const subthirthy = addresses.filter((o) => o.duration < 1800).length;
      setAverageTime(averageT);
      setAverageDistance(averageD);
      setUnderThirthy(subthirthy);
      setOriginsArray(addresses);
      setPercent(Math.round(100 * subthirthy / addresses.length, 0))
      setStep(3);
    }
  }, [output, addresses]);

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const handleReset = () => {
    setData([]);
    setOutput([]);
  };
  const handleUpload = (d) => {
    setData(d);
    console.log(d);
  };
  async function getGplaces(url, arr) {
    return;
    const urlInt = 'https://plejsapp-api.herokuapp.com/api/v1/missions/gplaces'
    const response = await axios.get(urlInt, {
      params: { url }
    });
    console.log('response', response, arr);
    const results = response.data.rows.map((r) => r.elements[0]) || [];
    const originsWithdata = arr.map((o, idx) => {
      const result = results[idx];
      return { ...o, ...result };
    });
    console.log('originswithdata', originsWithdata);
    setOutput(originsWithdata);
    uploadOutput(originsWithdata);
  }

  const handlePlace = (pl) => {
    setPlace(pl);
  };
  async function uploadOutput(output) {
    const url = 'https://plejsapp-api.herokuapp.com/api/v1/traveltimes/create';

    for (let i = 1; i < output.length; i += 1) {
      console.log('i', i);
      const body = {
        ...output,
        journey_id: journey.id,
        company_id: journey.company_id,
        has_bike: output[i].isBike,
        kids: output[i].kids,
        duration: output[i].duration.value,
        distance: output[i].distance.value,
        zip: output[i].zipcode,
        country: output[i].country,
        city: output[i].city,
        address: output[i].address,
        country: "SWEDEN"
      };
      const response = await axios.post(url, body)
        .then((response) => {
          console.log('response', response);
        })
        ;
    }
  };

  const handleOkSample = () => {
    console.log(sample);
    let origins = '';
    let originArray = [];
    for (let i = 1; i < sample.length; i += 1) {
      const id = sample[i][0];
      const address = sample[i][1];
      const zipcode = sample[i][2];
      const city = sample[i][3];
      const isBike = sample[i][4];
      const kids = sample[i][5];
      const obj = {
        id,
        address,
        zipcode,
        city,
        isBike,
        kids
      };
      const originText = `${address}%20${zipcode}%20${city}`;
      console.log('origintext', originText);
      originArray.push(obj);
      origins += originText;
      if (i !== sample.length - 1) {
        origins += '|';
      }
    }
    setOpen(false);
    setOriginsString(origins);
    setOriginsArray(originArray);
    setStep(2);
  };
  const handleOk = () => {
    let origins = '';
    let originArray = [];
    for (let i = 1; i < data.length; i += 1) {
      const id = data[i][0];
      const address = data[i][1];
      const zipcode = data[i][2];
      const city = data[i][3];
      const isBike = data[i][4];
      const kids = data[i][5];
      const obj = {
        id,
        address,
        zipcode,
        city,
        isBike,
        kids
      };
      const originText = `${address}%20${zipcode}%20${city}`;
      originArray.push(obj);
      origins += originText;
      if (i !== data.length - 1) {
        origins += '|';
      }
    }
    setOpen(false);
    setOriginsString(origins);
    setOriginsArray(originArray);
    setStep(2);
  };
  console.log('arrays', originsArray, addresses);
  return (
    <RootStyleFour>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{translate('Upload origins')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{translate('Make sure to use template formatting.')}</DialogContentText>
          <CsvUploader onUpload={handleUpload} />
          <Button onClick={handleOkSample}>{translate('Use Sample data')}</Button>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>{translate('Cancel')}</Button>
          <Button onClick={handleOk}>{translate('Add')}</Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stepper step={step - 1} />
          {!originsArray.length && !addresses.length && (
            <>
              <Button fullWidth variant="contained" onClick={handleOpen} startIcon={<UploadFileOutlined />}>{translate('Upload origins')}</Button>
            </>
          )}
          {(originsArray.length && place) && (
            <Grid container spacing={3} sx={{ alignItems: 'center' }}>
              <Grid item xs={12} >
                <InsightCard
                  heading={translate('Average travel time')}
                  unit={'min'}
                  value={averageTime}
                />
              </Grid>
              <Grid item xs={12}>
                <InsightCard
                  heading={translate('Average travel distance')}
                  unit={'km'}
                  value={averageDistance}
                />
              </Grid>
              <Grid item xs={12} >
                <InsightCard
                  heading={translate('Employees within 30 min')}
                  unit={`(${percent}%)`}
                  value={underThirty}
                />
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          {originsArray && originsArray.length > 0 && (
            <AddressForm onPlace={handlePlace} originsArray={originsArray} place={place} output={addresses.length ? addresses : output} />
          )}
        </Grid>
        {output.length ? (
          <Grid item xs={12}>
            <Typography variant="h4" color={theme.palette.primary.main}>
              {translate('Download Output')}
            </Typography>
            <br />
            <CSVLink data={output} headers={HEADERS}>
              {translate('Download csv now')}
            </CSVLink>
          </Grid>
        ) : null}
      </Grid>
    </RootStyleFour>
  );
}
