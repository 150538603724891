import { Icon } from '@iconify/react';
import trendingUpFill from '@iconify/icons-eva/trending-up-fill';
import trendingDownFill from '@iconify/icons-eva/trending-down-fill';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, Card, Typography, Stack } from '@mui/material';
// utils
import { fPercent } from '../../../utils/formatNumber';
import useLocales from '../../../hooks/useLocales';
import SoftTypography from '../../SoftTypography';

// ----------------------------------------------------------------------

const IconWrapperStyle = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  color: theme.palette.success.main,
  backgroundColor: alpha(theme.palette.success.main, 0.16)
}));

// ----------------------------------------------------------------------

const PERCENT = 2.6;

export default function InsightBlock({ heading, unit, value }) {
  const theme = useTheme();

  return (
    <Card sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
      <Box sx={{ flexGrow: 1, mr: { xs: 0 } }}>
        <SoftTypography color="primary" textGradient variant="h4">{heading}</SoftTypography>
        <Stack direction="row" alignItems="center" spacing={1} sx={{ mt: 1, mb: 1 }}>
          <IconWrapperStyle
            sx={{
              ...(value > 40.0 && {
                color: 'error.main',
                bgcolor: alpha(theme.palette.error.main, 0.16)
              })
            }}
          >
            <Icon width={16} height={16} icon={trendingUpFill} />
          </IconWrapperStyle>
          <Typography component="span" variant="subtitle2">
            {value} {unit}
          </Typography>
        </Stack>
      </Box>
    </Card>
  );
}
