import {
  Box,
  Button,
  Stack,
  Step,
  StepLabel,
  Stepper
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useAuth from "../../../hooks/useAuth.js";

// @mui material components
import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../../../redux/store.js";
import { useSoftUIController } from "../../../softcontext/index.js";
// Project page components
import Geocode from 'react-geocode';
import SoftSelect from "../../../components/SoftSelect/index.js";
import SoftTypography from "../../../components/SoftTypography/index.js";
import { geoConfig } from '../../../config';

import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import useIsMountedRef from "../../../hooks/useIsMountedRef.js";
import { getCompanyJourneys } from "../../../redux/slices/deal.js";

Geocode.setApiKey(geoConfig);

// ----------------------------------------------------------------------

const ContentStyleTwo = styled('div')(({ theme }) => ({
  maxWidth: 640,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function HomeCompany({ onCompany }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, login, logout } = useAuth();
  const { company } = useSelector((state) => state.company);
  const { journeys, journeysReceived } = useSelector((state) => state.site);
  const [searchParam, setSearchParam] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [companyExtended, setCompanyExtended] = useState('');
  const [revisedCompany, setRevisedCompany] = useState('');

  const isMountedRef = useIsMountedRef();

  const [controller] = useSoftUIController();
  const { sidenavColor } = controller;

  useEffect(() => {
    if (journeys.length > 0) {
      console.log('journeys', journeys);

      //navigate('/dashboard/manager-quick-scan');
    } else if (journeysReceived) {
      console.log('no journeys');
    } else {
      console.log('still loading');
    }
  }, [journeys, journeysReceived]);
  const handleLoginAuth0 = async () => {
    try {
      await login();
    } catch (error) {
      console.error(error);
    }
  };

  const handleCompanyInputSelect = async (searchParam) => {
    //if (!event?.target?.value) return;
    console.log('seraching for', searchParam);
    const urlvalue = encodeURI(searchParam);
    const searchUrl = `https://api.tic.io/search/companies?q=${urlvalue}&query_by=names&key=lLCv49FpxJghcKPR8vya0OMM9FVOaaRC`;
    const response = await axios.get(searchUrl);
    console.log('response', response);
    setSearchResults(response.data.hits)
  }

  const inputChange = (event) => {
    console.log('input changed', event);
    setSearchParam(event);
  }
  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleCompanyInputSelect(searchParam);
    }
  };

  const handleSelectCompany = (event, value) => {
    console.log('selected company', event, value);
    const chosenCompany = searchResults.find((s) => s.document.companyId === event.value);
    setCompanyExtended(chosenCompany);
    console.log('chosen company', chosenCompany);
    const doc = chosenCompany.document;
    const location = (doc?.addresses?.length > 1 && doc?.addresses[1]?.location?.length > 1) ? doc.addresses[1].location : doc.addresses[0].location;
    if (location) {
      const editedCompany = { externalId: chosenCompany.document.id, name: chosenCompany.document.names[0].nameOrIdentifier, org_number: chosenCompany.document.registrationNumber, latitude: location[0], longitude: location[1] };
      setRevisedCompany(editedCompany)

    } else {
      const editedCompany = { externalId: chosenCompany.document.id, name: chosenCompany.document.names[0].nameOrIdentifier, org_number: chosenCompany.document.registrationNumber };
      setRevisedCompany(editedCompany)

    }
    //createCompany(revisedCompany);
  }

  async function createCompany() {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/companies/create`;
    console.log('revised company', revisedCompany);
    const company = revisedCompany
    try {
      const response = await axios.post(url, company);
      console.log(response.data); // Assuming you want to log each successful response
      // updateUser(response.data.company.id, revisedCompany.name);
      dispatch(getCompanyJourneys(response.data.company.id));
      //createJourney(response.data.company.id, revisedCompany.name);
      onCompany(company);
    } catch (error) {
      console.error(error.message); // Log the error message if the request fails
    }
  }
  async function createJourney(id, name) {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/journeys/create`;
    console.log('revised company', revisedCompany);
    try {
      const response = await axios.post(url, { company_id: id });
      console.log(response.data); // Assuming you want to log each successful response
      updateUser(id, name, response.data.id);

      //updateUser(response.data.company.id, revisedCompany.name);
      //onCompany(company);
    } catch (error) {
      console.error(error.message); // Log the error message if the request fails
    }
  }
  async function updateUser(id, name, journey_id) {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/companies/updateuser`;
    try {
      const response = await axios.put(url, {
        id,
        user_id: user.id,
        name,
        journey_id
      });
      console.log('User', response.data); // Assuming you want to log each successful response
      if (response.data?.user_metadata?.company_id) {
        console.log('company id', response.data.user_metadata.company_id);
        handleLoginAuth0();
      }
    } catch (error) {
      console.error(error.message); // Log the error message if the request fails
    }
  }

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');
      if (isMountedRef.current) {
        console.log(' handleClose();');
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };
  return (
    <ContentStyleTwo>

      <Box sx={{ p: 2, pt: 1.5, position: "fixed", top: 5, right: 5 }}>
        <Button color="error" variant="outlined" onClick={handleLogout}>
          Logout
        </Button>
      </Box>
      <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
        <Box sx={{ width: '50%' }}>
          <img src="/static/lohow.png" alt="login" />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <SoftTypography variant="h4" gutterBottom>
            Great to have you onboard!
          </SoftTypography>
          <SoftTypography sx={{ color: 'text.secondary' }}>
            Please tell us which company you are from
          </SoftTypography>
          <SoftSelect onKeyDown={handleEnter} onInputChange={inputChange} onChange={handleSelectCompany} onSelect={handleSelectCompany} options={searchResults.map((s) => ({ label: s.document?.names[0]?.nameOrIdentifier, value: s.document.companyId }))} />

          <Button onClick={createCompany} fullWidth variant="contained" color="primary" sx={{ mt: 2 }}>Let's Go!</Button>
        </Box>
      </Stack>
      <Box sx={{ width: '100%', position: "absolut", bottom: 2, }}>
        <Stepper activeStep={1} alternativeLabel>
          {["Create User", "Connect Company", "Manager Quick Scan", "Scorecard", "Employee perspective", "Insights", "Premises programs"].map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>
    </ContentStyleTwo>
  );
}
