import {
  Box,
  Button,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography
} from "@mui/material";
import { styled } from "@mui/material/styles";
import useAuth from "../../hooks/useAuth.js";

// @mui material components
import axios from "axios";
import { useState } from "react";
import { useDispatch, useSelector } from "../../redux/store.js";
import { useSoftUIController } from "../../softcontext/index.js";
// Project page components
import SoftSelect from "../../components/SoftSelect/index.js";
import SoftTypography from "../../components/SoftTypography/index.js";
import { geoConfig } from '../../config';
import Geocode from 'react-geocode';

import { createCompany } from "../../redux/slices/company.js";

Geocode.setApiKey(geoConfig);

// ----------------------------------------------------------------------

const ContentStyleTwo = styled('div')(({ theme }) => ({
  maxWidth: 640,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

// ----------------------------------------------------------------------

export default function CompanyDetails({ onCompany }) {
  const dispatch = useDispatch();
  const { user, login } = useAuth();
  const { company } = useSelector((state) => state.company);
  const [searchParam, setSearchParam] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [companyExtended, setCompanyExtended] = useState('');
  const [revisedCompany, setRevisedCompany] = useState('');

  const [controller] = useSoftUIController();
  const { sidenavColor } = controller;

  const inputChange = (event) => {
    console.log('input changed', event);
    setSearchParam(event);
  }
  const handleCompanyInputSelect = async (searchParam) => {
    //if (!event?.target?.value) return;
    console.log('seraching for', searchParam);
    const urlvalue = encodeURI(searchParam);
    const searchUrl = `https://api.tic.io/search/companies?q=${urlvalue}&query_by=names&key=lLCv49FpxJghcKPR8vya0OMM9FVOaaRC`;
    console.log('searchUrl', searchUrl);
    const response = await axios.get(searchUrl);
    console.log('response', response);
    setSearchResults(response.data.hits)
  }
  const handleEnter = (e) => {
    if (e.key === "Enter") {
      handleCompanyInputSelect(searchParam);
    }
  };

  const handleSelectCompany = (event, value) => {
    console.log('selected company', event, value);
    const chosenCompany = searchResults.find((s) => s.document.companyId === event.value);
    setCompanyExtended(chosenCompany);
    console.log('chosen company', chosenCompany);
    const doc = chosenCompany.document;
    const location = (doc?.addresses?.length > 1 && doc?.addresses[1]?.location?.length > 1) ? doc.addresses[1].location : doc.addresses[0].location;
    if (location) {
      const editedCompany = { externalId: chosenCompany.document.id, name: chosenCompany.document.names[0].nameOrIdentifier, org_number: chosenCompany.document.registrationNumber, latitude: location[0], longitude: location[1] };
      setRevisedCompany(editedCompany)

    } else {
      const editedCompany = { externalId: chosenCompany.document.id, name: chosenCompany.document.names[0].nameOrIdentifier, org_number: chosenCompany.document.registrationNumber };
      setRevisedCompany(editedCompany)

    }
    //createCompany(revisedCompany);
  }

  return (
    <ContentStyleTwo>
      <Stack direction="row" alignItems="center" sx={{ mb: 1 }}>
        <Box sx={{ width: '50%' }}>
          <img src="/static/lohow.png" alt="login" />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <SoftTypography variant="h4" gutterBottom>
            Company information tool
          </SoftTypography>
          <SoftTypography sx={{ color: 'text.secondary' }}>
           Search for a company to get more information
          </SoftTypography>
          <SoftSelect onKeyDown={handleEnter} onInputChange={inputChange} onChange={handleSelectCompany} onSelect={handleSelectCompany} options={searchResults.map((s) => ({ label: s.document?.names[0]?.nameOrIdentifier, value: s.document.companyId }))} />
        </Box>
      </Stack>
      {companyExtended && (

        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" gutterBottom>
            {companyExtended.document.names[0].nameOrIdentifier}
            </Typography>
            <Typography variant="h6" gutterBottom>
            Name
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" gutterBottom>
            {companyExtended.document.addresses[0].street}
            </Typography>
            <Typography variant="h6" gutterBottom>
            Address
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" gutterBottom>
            {companyExtended.document.registrationDate}
            </Typography>
            <Typography variant="h6" gutterBottom>
            Registration date
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h3" gutterBottom>
            {companyExtended.document.registrationNumber}
            </Typography>
            <Typography variant="h6" gutterBottom>
            Org number
            </Typography>
          </Grid>
        </Grid>
      )}
    </ContentStyleTwo>
  );
}
