/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";

import ProductCell from "./components/ProductCell";
// Soft UI Dashboard PRO React components
import SoftBox from "../../../components/SoftBox";
import SoftButton from "../../../components/SoftButton";
import SoftTypography from "../../../components/SoftTypography";

// Soft UI Dashboard PRO React example components
import Footer from "../../../examples/Footer";
import DashboardLayout from "../../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../../examples/Navbars/DashboardNavbar";
import DataTable from "../../../examples/Tables/DataTable";

// Data
import { getActivityprofiles } from "../../../redux/slices/workplace";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SoftBadge from "../../../components/SoftBadge";
import ActionCell from "./components/ActionCell";

function ProductsList() {

  const dispatch = useDispatch();
  const { activityprofiles } = useSelector((state) => state.workplace);
  const [modprf, setModprf] = useState(activityprofiles);
  useEffect(() => {
    if (!activityprofiles.length) {
      dispatch(getActivityprofiles());
    };
  }, [dispatch]);
  useEffect(() => {
    if (!activityprofiles.length) return;
    const modprofiles = activityprofiles.map((a) => {
      const pr = { ...a, action: <ActionCell />, route: `/activities/${a.id}`  };
      return pr;
    })
    setModprf(modprofiles);
  }, [activityprofiles]);
  console.log('workplaces, ', activityprofiles);
  
  const outOfStock = (
    <SoftBadge variant="contained" color="error" size="xs" badgeContent="Private" container />
  );
  const midstock = (
    <SoftBadge variant="contained" color="warning" size="xs" badgeContent="Semi private" container />
  );
  const inStock = (
    <SoftBadge variant="contained" color="success" size="xs" badgeContent="Public" container />
  );

  const dataTableData = {
    columns: [
      { Header: "name", accessor: "name" },
      { Header: "description", accessor: "description" },
      { Header: "count", accessor: "count" },
      {
        Header: "privacy",
        accessor: "individual_id",
        Cell: ({ value }) => (value === 1 ? inStock : value === 2 ? midstock : outOfStock),
      },

      { Header: "action", accessor: "action" },
    ],
    rows: modprf
  };

  return (
    <DashboardLayout>
      <SoftBox my={3}>
        <Card>
          <SoftBox display="flex" justifyContent="space-between" alignItems="flex-start" p={3}>
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                All Activities
              </SoftTypography>
              <SoftTypography variant="button" fontWeight="regular" color="text">
                Descriptions to better explain the properties of each activity.
              </SoftTypography>
            </SoftBox>
            <Stack spacing={1} direction="row">
              {/* <Link to="/ecommerce/products/new-product">
                <SoftButton variant="gradient" color="info" size="small">
                  + new activity
                </SoftButton>
              </Link> */}
              <SoftButton variant="outlined" color="info" size="small">
                import
              </SoftButton>
              <SoftButton variant="outlined" color="info" size="small">
                export
              </SoftButton>
            </Stack>
          </SoftBox>
          <DataTable
            table={dataTableData}
            entriesPerPage={{
              defaultValue: Math.min(10, dataTableData.rows.length),
              entries: [5, 7, 10, 15, 20, 25],
            }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProductsList;
