import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent, { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import { Addchart, Check, ChevronLeft, ChevronRight, Dashboard, DocumentScanner, FileCopy, LocationCity, LockClock, PanoramaFishEyeSharp, Person, Star, TextFields } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export default function IntroTimeline({ stepz, onStage, selectedValue }) {
  const steps = [true, true, false, false, false, false, false, false]
  return (
    <Timeline sx={{
      [`& .${timelineOppositeContentClasses.root}`]: {
        flex: 0.2,
      },
    }}>


      <TimelineItem onClick={() => onStage(1)}>
        <TimelineSeparator>
          <TimelineConnector sx={steps[1] && { bgcolor: 'success.main' }} />
          {steps[1] ? (
            <TimelineDot color="success" variant="outlined">
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant={selectedValue === 1 ? "filled" :"outlined"}>
              <DocumentScanner />
            </TimelineDot>
          )}
          <TimelineConnector sx={steps[1] && { bgcolor: 'success.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          
          <Typography>Management Survey</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem onClick={() => onStage(2)}>
        <TimelineSeparator>
          <TimelineConnector sx={steps[2] && { bgcolor: 'success.main' }} />
          {steps[2] ? (
            <TimelineDot color="success" variant={selectedValue === 2 ? "filled" :"outlined"}>
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant={selectedValue === 2 ? "filled" :"outlined"}>
              <LocationCity />
            </TimelineDot>
          )}
          <TimelineConnector sx={steps[2] && { bgcolor: 'success.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography>Current Premises</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem onClick={() => onStage(3)}>
        <TimelineSeparator>
          <TimelineConnector sx={steps[3] && { bgcolor: 'secondary.main' }} />
          {steps[3] ? (
            <TimelineDot color="success" variant="outlined">
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant={selectedValue === 3 ? "filled" :"outlined"}>
              <FileCopy />
            </TimelineDot>
          )}
          <TimelineConnector sx={steps[3] && { bgcolor: 'secondary.main' }} />
        </TimelineSeparator >
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography>Employee Survey</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem onClick={() => onStage(4)}>
        <TimelineSeparator>
          <TimelineConnector sx={steps[4] && { bgcolor: 'secondary.main' }} />
          {steps[4] ? (
            <TimelineDot color="success" variant="outlined">
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant="outlined">
              <LockClock />
            </TimelineDot>
          )}
          <TimelineConnector sx={steps[4] && { bgcolor: 'secondary.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography>Survey closed</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem onClick={() => onStage(5)}>
        <TimelineSeparator>
          <TimelineConnector sx={steps[5] && { bgcolor: 'secondary.main' }} />
          {steps[5] ? (
            <TimelineDot color="success" variant="outlined">
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant="outlined">
              <Dashboard />
            </TimelineDot>
          )}
          <TimelineConnector sx={steps[5] && { bgcolor: 'secondary.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography>Workplacer Review</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          {steps[6] ? (
            <TimelineDot color="success" variant="outlined">
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant="outlined">
              <Addchart />
            </TimelineDot>
          )}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography>Unlock Workplacer Pro</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector />
          {steps[7] ? (
            <TimelineDot color="success" variant="outlined">
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant="outlined">
              <Star />
            </TimelineDot>
          )}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography>Extraordinary Workplace</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}