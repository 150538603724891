// hooks
//
import createAvatar from '../utils/createAvatar';

import { Avatar } from '@mui/material';
// ----------------------------------------------------------------------

export default function CompanyAvatar({ company, ...other }) {
  return (
    <Avatar
      src={company?.photoUrl || company?.logo?.url || '/static/lohow.png'}
      alt={company?.name}
      color={company?.photoUrl ? 'default' : createAvatar(company?.name).color}
      {...other}
    >
      {createAvatar(company?.name).name}
    </Avatar>
  );
}
