import { Box, Button, CardActionArea, Drawer, Stack, Tooltip, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { DocIllustration } from '../../assets';
import { MHidden } from '../../components/@material-extend';
import LoadingScreen from '../../components/LoadingScreen';
import Logo from '../../components/Logo';
import NavSection from '../../components/NavSection';
import Scrollbar from '../../components/Scrollbar';
import useAuth from '../../hooks/useAuth';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
import useLocales from '../../hooks/useLocales';
import sidebarConfig from './SidebarConfigPlace';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const COLLAPSE_WIDTH = 102;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    flexShrink: 0,
    transition: theme.transitions.create('width', {
      duration: theme.transitions.duration.complex
    })
  }
}));

// ----------------------------------------------------------------------

IconCollapse.propTypes = {
  onToggleCollapse: PropTypes.func,
  collapseClick: PropTypes.bool
};

function IconCollapse({ onToggleCollapse, collapseClick }) {
  return (
    <Tooltip title="Mini Menu">
      <CardActionArea
        onClick={onToggleCollapse}
        sx={{
          width: 18,
          height: 18,
          display: 'flex',
          cursor: 'pointer',
          borderRadius: '50%',
          alignItems: 'center',
          color: 'text.primary',
          justifyContent: 'center',
          border: 'solid 1px currentColor',
          ...(collapseClick && {
            borderWidth: 2
          })
        }}
      >
        <Box
          sx={{
            width: 8,
            height: 8,
            borderRadius: '50%',
            bgcolor: 'currentColor',
            transition: (theme) => theme.transitions.create('all'),
            ...(collapseClick && {
              width: 0,
              height: 0
            })
          }}
        />
      </CardActionArea>
    </Tooltip>
  );
}

NeoLayout.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function NeoLayout({ isOpenSidebar, onCloseSidebar, empty }) {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const { email } = user;
  const { currentLang, translate } = useLocales();
  const { place } = useSelector((state) => state.place);
  const { isCollapse, collapseClick, collapseHover, onToggleCollapse, onHoverEnter, onHoverLeave } =
    useCollapseDrawer();


  // const internal = email.includes('gmail');
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // esslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  if (!user) return <LoadingScreen />;
  const internal = user?.internal;
  const sidebarMod = user?.internal ? sidebarConfig : sidebarConfig;

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': {
          height: 1,
          display: 'flex',
          flexDirection: 'column'
        }
      }}
    >
      <Stack
        spacing={3}
        sx={{
          px: 2.5,
          pt: 3,
          pb: 2,
          ...(isCollapse && {
            alignItems: 'center'
          })
        }}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
            {isCollapse ? <Logo /> : (
              <Logo />
            )}
          </Box>
          {!isCollapse && (
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="subtitle2">
                {translate('Workplacer.ai')}
              </Typography>
            </Box>
          )}
          <MHidden width="mdDown">
            {!isCollapse && (
              <Stack direction="row" spacing={3} alignItems='center'>
                <IconCollapse onToggleCollapse={onToggleCollapse} collapseClick={collapseClick} />
              </Stack>
            )}
          </MHidden>
        </Stack>
      </Stack >
      {!empty && (
        <NavSection key="se" navConfig={sidebarMod} isShow={!isCollapse} user={user} />
      )
      }
      <Box sx={{ flexGrow: 1 }} />

      {
        !isCollapse && (
          <Stack spacing={3} alignItems="center" sx={{ px: 5, pb: 5, mt: 5, width: 1, textAlign: 'center' }}>
            <DocIllustration sx={{ width: 1 }} />
            <div>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                {translate('Enabling extraordinary workplaces.')}
              </Typography>
            </div>
            <Button href="mailto:jesper.cronsioe@mindrealestate.se" target="_blank" rel="noreferrer" variant="contained">
              {translate('Support')}
            </Button>
          </Stack>
        )
      }
    </Scrollbar >
  );
  return (

    <RootStyle
      sx={{
        width: {
          md: isCollapse ? COLLAPSE_WIDTH : DRAWER_WIDTH
        },
        ...(collapseClick && {
          position: 'absolute'
        })
      }}
    >
      <MHidden width="mdUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="mdDown">
        <Drawer
          open
          variant="persistent"
          onMouseEnter={onHoverEnter}
          onMouseLeave={onHoverLeave}
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              ...(isCollapse && {
                width: COLLAPSE_WIDTH
              }),
              ...(collapseHover && {
                borderRight: 0,
                backdropFilter: 'blur(6px)',
                WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
                boxShadow: (theme) => theme.customShadows.z20,
                bgcolor: (theme) => alpha(theme.palette.background.default, 0.88)
              })
            }
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
