import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import FastfoodIcon from '@mui/icons-material/Fastfood';
import LaptopMacIcon from '@mui/icons-material/LaptopMac';
import HotelIcon from '@mui/icons-material/Hotel';
import RepeatIcon from '@mui/icons-material/Repeat';
import Typography from '@mui/material/Typography';
import { Box, Stack } from '@mui/material';
import { Addchart, Check, ChevronLeft, ChevronRight, Dashboard, DocumentScanner, FileCopy, LocationCity, LockClock, PanoramaFishEyeSharp, Person, Star, TextFields } from '@mui/icons-material';
import { Link } from 'react-router-dom';

export default function IntroTimeline({ stepz }) {
  const steps = [true, false, false, false, false, false, false, false]
  return (
    <Timeline position="alternate">

      <TimelineItem>
        <TimelineSeparator>
          <TimelineConnector sx={steps[0] && { bgcolor: 'success.main' }} />
          {steps[0] ? (
            <TimelineDot color="success" variant="outlined">
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant="outlined">
              <Person />
            </TimelineDot>
          )}
          <TimelineConnector sx={steps[0] && { bgcolor: 'success.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Account created
          </Typography>
          <Typography>Sign up</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >

          <Link to="/surveys/surveyindividual">
            <ChevronRight />
          </Link>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={steps[1] && { bgcolor: 'success.main' }} />
          {steps[1] ? (
            <TimelineDot color="success" variant="outlined">
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant="outlined">
              <DocumentScanner />
            </TimelineDot>
          )}
          <TimelineConnector sx={steps[1] && { bgcolor: 'success.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Provide your view of how the organization works
          </Typography>
          <Typography>Take Management Survey</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          <ChevronLeft />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={steps[2] && { bgcolor: 'success.main' }} />
          {steps[2] ? (
            <TimelineDot color="success" variant="outlined">
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant="outlined">
              <LocationCity />
            </TimelineDot>
          )}
          <TimelineConnector sx={steps[2] && { bgcolor: 'success.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Fill in information about your current premises
          </Typography>
          <Typography>Complete Premises Information</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          <Link to="/surveys/surveyemployee">
            <ChevronRight />
          </Link>
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={steps[3] && { bgcolor: 'secondary.main' }} />
          {steps[3] ? (
            <TimelineDot color="success" variant="outlined">
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant="outlined">
              <FileCopy />
            </TimelineDot>
          )}
          <TimelineConnector sx={steps[3] && { bgcolor: 'secondary.main' }} />
        </TimelineSeparator >
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Send out survey to get employee perspective
          </Typography>
          <Typography>Send Employee Survey</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          <ChevronLeft />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={steps[4] && { bgcolor: 'secondary.main' }} />
          {steps[4] ? (
            <TimelineDot color="success" variant="outlined">
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant="outlined">
              <LockClock />
            </TimelineDot>
          )}
          <TimelineConnector sx={steps[4] && { bgcolor: 'secondary.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Responses collected
          </Typography>
          <Typography>Survey closed</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          <ChevronRight />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector sx={steps[5] && { bgcolor: 'secondary.main' }} />
          {steps[5] ? (
            <TimelineDot color="success" variant="outlined">
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant="outlined">
              <Dashboard />
            </TimelineDot>
          )}
          <TimelineConnector sx={steps[5] && { bgcolor: 'secondary.main' }} />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Analyze your workplace potential
          </Typography>
          <Typography>Review Workplace Needs</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          <ChevronLeft />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          {steps[6] ? (
            <TimelineDot color="success" variant="outlined">
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant="outlined">
              <Addchart />
            </TimelineDot>
          )}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Create layouts, premises program, budgets and requirement specifications
          </Typography>
          <Typography>Unlock Workplacer Pro</Typography>
        </TimelineContent>
      </TimelineItem>

      <TimelineItem>
        <TimelineOppositeContent
          sx={{ m: 'auto 0' }}
          variant="body2"
          color="text.secondary"
        >
          <ChevronLeft />
        </TimelineOppositeContent>
        <TimelineSeparator>
          <TimelineConnector />
          {steps[7] ? (
            <TimelineDot color="success" variant="outlined">
              <Check />
            </TimelineDot>
          ) : (
            <TimelineDot variant="outlined">
              <Star />
            </TimelineDot>
          )}
          <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Typography variant="h6" component="span">
            Find your extraordinary workplace
          </Typography>
          <Typography>Extraordinary Workplace</Typography>
        </TimelineContent>
      </TimelineItem>
    </Timeline>
  );
}