/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import { Icon, IconButton, Stack } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import SoftAvatar from "../../../components/SoftAvatar";
import SoftBox from "../../../components/SoftBox";
import SoftInput from "../../../components/SoftInput";
import SoftTypography from "../../../components/SoftTypography";
import { useSelector } from "react-redux";
import { dispatch } from "../../../redux/store";
import { updateDepartment } from "../../../redux/slices/company";
import { CircularProgress } from "@mui/material";

function Departments({ title, deps }) {
  const { company } = useSelector((state) => state.company);
  const [departments, setDepartments] = useState(deps || []);
  const [newDepartment, setNewDepartment] = useState('');
  const [updateTimeout, setUpdateTimeout] = useState(null);
  const [loadingStates, setLoadingStates] = useState({});
  const [successStates, setSuccessStates] = useState({});

  useEffect(() => {
    console.log('deps', deps);
    setDepartments(deps?.filter((dep) => !dep.journey_id));
  }, [deps]);

  const handleAdd = () => {
    console.log('add', newDepartment);
    createDepartment(newDepartment);
  }
  console.log('departments', deps);

  async function createDepartment(name) {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/departments/create`;
    try {
      await axios.post(url, { company_id: company.id, name })
        .then((res) => {
          console.log(res.data);
          setDepartments((prev) => [...prev, res.data]);
          setNewDepartment('');
        });
    } catch (error) {
      console.error(error.message); // Log the error message if the request fails
    }
  }
  async function deleteDepartment(id) {
    const url = `https://plejsapp-api.herokuapp.com/api/v1/departments/delete/${id}`;
    await axios.delete(url)
      .then((res) => {
        console.log(res.data);
        setDepartments((prev) => prev.filter((dep) => dep.id !== id));
      });
  }
  return (
    <Card mb={2}>
      <SoftBox pt={2} px={2}>
        <SoftTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </SoftTypography>
      </SoftBox>
      <SoftBox p={2}>
        <SoftBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {departments?.length && departments?.map((dep, index) => (
            <SoftBox key={index} component="li" display="flex" alignItems="center" py={1} mb={1}>
              <SoftBox mr={2}>
                <SoftAvatar icon={<Icon>account_circle</Icon>} alt="something here" variant="rounded" shadow="md" />
              </SoftBox>
              <SoftBox
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                justifyContent="center"
                flexGrow={1}
              >
                <SoftTypography variant="button" fontWeight="medium">
                  {dep.name}
                </SoftTypography>
              </SoftBox>
              <SoftBox>
                <SoftInput
                  value={dep.headcount}
                  onChange={(e) => {
                    const newValue = e.target.value;
                    setDepartments(prev => prev.map(d => 
                      d.id === dep.id ? { ...d, headcount: newValue } : d
                    ));
                    
                    if (updateTimeout) clearTimeout(updateTimeout);
                    
                    setLoadingStates(prev => ({ ...prev, [dep.id]: true }));
                    setSuccessStates(prev => ({ ...prev, [dep.id]: false }));
                    
                    const timeoutId = setTimeout(async () => {
                      try {
                        await dispatch(updateDepartment(dep.id, {headcount: newValue}));
                        
                        setLoadingStates(prev => ({ ...prev, [dep.id]: false }));
                        setSuccessStates(prev => ({ ...prev, [dep.id]: true }));
                        
                        setTimeout(() => {
                          setSuccessStates(prev => ({ ...prev, [dep.id]: false }));
                        }, 2000);
                      } catch (error) {
                        setLoadingStates(prev => ({ ...prev, [dep.id]: false }));
                        // Optionally handle error state here
                      }
                    }, 500);
                    
                    setUpdateTimeout(timeoutId);
                  }}
                />
              </SoftBox>
              {loadingStates[dep.id] ? (
                <IconButton disabled>
                  <CircularProgress size={20} />
                </IconButton>
              ) : successStates[dep.id] ? (
                <IconButton disabled>
                  <Icon sx={{ color: 'success.main' }}>check_circle</Icon>
                </IconButton>
              ) : (
                <IconButton variant="gradient" color="error" onClick={() => deleteDepartment(dep.id)}>
                  <Icon>delete</Icon>
                </IconButton>
              )}
            </SoftBox>
          ))}
        </SoftBox>
      </SoftBox>
      <SoftBox p={2}>
        <Stack direction="row" spacing={2}>
          <SoftInput
            placeholder="Department Name"
            value={newDepartment}
            onChange={(e) => setNewDepartment(e.target.value)}
          />
          <IconButton variant="gradient" color="success" onClick={handleAdd}>
            <Icon>add</Icon>
          </IconButton>
        </Stack>
      </SoftBox>
    </Card>
  );
}

// Typechecking props for the ProfilesList
Departments.propTypes = {
  title: PropTypes.string.isRequired,
  profiles: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Departments;
